
import { defineComponent, ref } from "vue";
import BankverbindungEditModal from "../Modals/BankverbindungEditModal.vue";

export default defineComponent ({
  name: "BankverbindungEditProfil",
  components: {
    BankverbindungEditModal
  },

  setup () {
    const bankverbindungModalRef = ref();

    function showModal() {
      bankverbindungModalRef.value?.showModal()
    }

    return {
      bankverbindungModalRef,
      showModal
    }
  },

})
