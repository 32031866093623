
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { GetProfilbildRequestData } from "@/models/Personal/PersonalModels"
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import NameUndAdresseEditProfilEN from "@/components/Vorgaenge/Profil/NameUndAdresseEditProfil_EN.vue";
import KontaktinformationenEditProfilEN from "@/components/Vorgaenge/Profil/KontaktinformationenEditProfil_EN.vue";
import BankverbindungEditProfilEN from "@/components/Vorgaenge/Profil/BankverbindungEditProfil_EN.vue";
import KrankenkasseEditProfilEN from "@/components/Vorgaenge/Profil/KrankenkasseEditProfil_EN.vue";

export default defineComponent({
  name: "Profil_EN",
  components: {
    LoadingSpinner,
    NameUndAdresseEditProfilEN,
    KontaktinformationenEditProfilEN,
    BankverbindungEditProfilEN,
    KrankenkasseEditProfilEN,
  },

  setup () {
    const store = useStore();

    // Computed
    const personaldaten = computed(() => store.getters.personaldaten);
    const archivUser = computed(() => store.getters.archivUser)
    const profilbild = computed(() => store.getters.profilbild);
    const loading = computed(() => store.getters.status.getPersonaldatenLoading);
    const profilbildLoading = computed(() => store.getters.status.personalProfilbildLoading);

    //Converter
    const geburtsdatumConverter = computed(() => {
      if (personaldaten.value.gebdat == null) {
        return ""
      }

      const today: Date = new Date();
      const gebdate: Date = new Date(personaldaten.value.gebdat.toString())

      if (gebdate.getDate() == today.getDate() && gebdate.getMonth() == today.getMonth() && gebdate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + gebdate.getDate()).slice(-2) +
      "." +
      ("0" + (gebdate.getMonth() + 1)).slice(-2) +
      "." +
      gebdate.getFullYear();
    })
    
    const adateConverter = computed(() => {
      const today: Date = new Date();
      const adate: Date = new Date(personaldaten.value.adate.toString())

      if (adate.getDate() == today.getDate() && adate.getMonth() == today.getMonth() && adate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + adate.getDate()).slice(-2) +
      "." +
      ("0" + (adate.getMonth() + 1)).slice(-2) +
      "." +
      adate.getFullYear();
    })
    
    const vdateConverter = computed(() => {
      if (personaldaten.value.vdate == null) {
        return ""
      }

      const vdate: Date = new Date(personaldaten.value.vdate.toString())

      if (vdate.getFullYear() == 1) {
        return "open-ended";
      }
      

      return ("0" + vdate.getDate()).slice(-2) +
      "." +
      ("0" + (vdate.getMonth() + 1)).slice(-2) +
      "." +
      vdate.getFullYear();
    })
    
    const rentenbeginnConverter = computed(() => {
      if (personaldaten.value.rentenbeginn == null) {
        return "";
      }

      const today: Date = new Date();
      const rentenbeginndate: Date = new Date(personaldaten.value.rentenbeginn.toString())

      if (rentenbeginndate.getDate() == today.getDate() && rentenbeginndate.getMonth() == today.getMonth() && rentenbeginndate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + rentenbeginndate.getDate()).slice(-2) +
      "." +
      ("0" + (rentenbeginndate.getMonth() + 1)).slice(-2) +
      "." +
      rentenbeginndate.getFullYear();
    })
    
    const rentenbeginnTimerConverter = computed(() => {
      if (personaldaten.value.rentenbeginn == null) {
        return "";
      }

      const today: Date = new Date();
      const rentenbeginndate: Date = new Date(personaldaten.value.rentenbeginn.toString())

      if (rentenbeginndate.getDate() == today.getDate() && rentenbeginndate.getMonth() == today.getMonth() && rentenbeginndate.getFullYear() == today.getFullYear()) {
        return "";
      }

      const oneDay = 1000 * 60 * 60 * 24;
      const oneYear = 365
      let diff = Math.floor(rentenbeginndate.getTime() - today.getTime());
      const years = Math.floor(diff / (oneDay * oneYear));
      diff -= years * (oneDay * oneYear);
      const days = Math.floor(diff / oneDay);

      return years + " Years & " + days + " Days";
    })

    const nameTitleconverter = computed(() => {
      if (personaldaten.value.titel == null || personaldaten.value.titel == "") {
        return `${personaldaten.value.vorname} ${personaldaten.value.name}`
      }
      else {
        return `${personaldaten.value.titel} ${personaldaten.value.vorname} ${personaldaten.value.name}`
      }
    })
    
    const geschlechtConverter = computed(() => {
      const value = personaldaten.value.geschlecht;

      let geschlecht = ""

      switch(value) {
        case "M":
        case "m":
          geschlecht = "Male";
          break;
        case "W":
        case "w":
          geschlecht = "Female";
          break;
        default:
          geschlecht = "Divers"
      }

      return geschlecht;
    })

    const ibanConverter = computed(() => {
      let iban = personaldaten.value.iban
      iban = iban.replace(/\s/g, '');
      return iban.match(/.{1,4}/g)?.join(' ');
    })

    return {
      store,
      personaldaten,
      archivUser,
      profilbild,
      loading,
      profilbildLoading,
      geburtsdatumConverter,
      adateConverter,
      vdateConverter,
      rentenbeginnConverter,
      rentenbeginnTimerConverter,
      nameTitleconverter,
      geschlechtConverter,
      ibanConverter
    }
  },

  methods: {
    updateData () {
      this.store.dispatch(PersonalActionTypes.GetPersonaldaten, undefined)
    },

    reloadImage () {
      const data = new GetProfilbildRequestData(this.personaldaten.archivname, this.personaldaten.archivmandant, this.archivUser.token);
      this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
    }
  },

  mounted () {
    const contextMenu = document.getElementById("context-menu");
    const scope = document.querySelector("body");
    const imgScope = document.getElementById("profilbild-container");


    imgScope?.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      const { clientX: mouseX, clientY: mouseY } = event;

      if (contextMenu != null) {
        contextMenu.style.top = `${mouseY}px`;
        contextMenu.style.left = `${mouseX}px`;

        contextMenu.classList.remove("visible");

        setTimeout(() => {
          contextMenu.classList.add("visible");
        })
      }
    })

    scope?.addEventListener("click", () => {
      if (contextMenu != null) {
        contextMenu.classList.remove("visible");
      }
    });
  }
})
