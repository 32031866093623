
import { defineComponent, ref } from "vue";
import BankverbindungEditModalEN from "../Modals/BankverbindungEditModal_EN.vue";

export default defineComponent ({
  name: "BankverbindungEditProfil",
  components: {
    BankverbindungEditModalEN
  },

  setup () {
    const bankverbindungModalENRef = ref();

    function showModal() {
      bankverbindungModalENRef.value?.showModal()
    }

    return {
      bankverbindungModalENRef,
      showModal
    }
  },
})
