
import SidebarNachricht from "./Sidebar/SidebarNachricht.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue"

import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

export default defineComponent({

  components: {
    SidebarNachricht,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const language = computed(() => store.getters.language);
    const posteingangUngelesen = computed(() => store.getters.postfachPosteingang.filter(p => p.status == 1 || p.status == 0))
    const loading = computed(() => store.getters.status.nachrichtenLoading)
    const einstellungLoading = computed(() => store.getters.status.personalEinstellungLoading)
    const newsEinstellung = computed(() => store.getters.newsEinstellung)
    const newsEinstellungErrorMsg = computed(() => store.getters.status.personalEinstellungErrorMsg)

    const sidebarRechtsCollapsed = computed(() => store.getters.status.sidebarRechtsCollapsed)

    return {
      store,
      sidebarRechtsCollapsed,
      language,
      posteingangUngelesen,
      loading,
      einstellungLoading,
      newsEinstellung,
      newsEinstellungErrorMsg
    }
  },

  methods: {
    toggleSidebarRechts () {
      this.store.dispatch(PersonalActionTypes.ToggleSidebarRechts, undefined);
    },
  },

  mounted () {
    this.store.dispatch(CommonActionTypes.GetNewsEinstellung, undefined)
  }
});
