
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import {v4 as uuidv4 } from 'uuid'

import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

export default defineComponent({
  name: "Terminal Test",

  setup () {
    const store = useStore();

    const terminalVersion = computed(() => store.getters.terminalVersion)
    const prevUrl = computed(() => store.getters.prevUrl != "" ? store.getters.prevUrl : "unbekannt")
    const personaldaten = computed(() => store.getters.personaldaten)
    const siteKiosk = window.hasOwnProperty("siteKiosk")

    const token = computed(() => store.getters.user.token)

    return {
      store,
      terminalVersion,
      prevUrl,
      personaldaten,
      siteKiosk,
      token
    }
  },

  data () {
    return {
      setTerminalVersionSwitch: false,
      guid: ""
    }
  },

  methods: {
    setTerminalVersion() {
      this.store.dispatch(CommonActionTypes.SetTerminalVersion, this.setTerminalVersionSwitch);
    },

    startPersonalScan() {
      console.log(`${this.guid} ${this.personaldaten.guid}`);
      if (window.hasOwnProperty("siteKiosk")) {
        (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', `${this.guid} ${this.personaldaten.guid}`)
      }
      else {
        console.log("SiteKiosk defined: " + window.hasOwnProperty("siteKiosk"))
      }
    },

    startTestPersonalScan() {
      console.log("param1 param2");
      if (window.hasOwnProperty("siteKiosk")) {
        (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', "param1 param2")
      }
      else {
        console.log("SiteKiosk defined: " + window.hasOwnProperty("siteKiosk"))
      }
    },
  }, 

  mounted () {
    this.setTerminalVersionSwitch = this.terminalVersion
    this.guid = uuidv4()
  }
});
