
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

import Profil_DE from "@/components/Profil/Profil_DE.vue"
import Profil_EN from "@/components/Profil/Profil_EN.vue"

export default defineComponent({
  name: "Profil",
  components: {
    Profil_DE,
    Profil_EN,
  },

  setup () {
    const store = useStore();

    const language = computed(() => store.getters.language)
    const posteingang = computed(() => store.getters.postfachPosteingang)
    const user = computed(() => store.getters.user)
    const archivUser = computed(() => store.getters.archivUser)
    const personaldaten = computed(() => store.getters.personaldaten)

    const personaldatenLastUpdate = computed(() => store.getters.personaldatenLastUpdate)
    const personaldatenLastUpdateInterval = computed(() => store.getters.personaldatenUpdateInterval)
    

    return {
      store,
      language,
      posteingang,
      user,
      archivUser,
      personaldaten,
      personaldatenLastUpdate,
      personaldatenLastUpdateInterval,
    }
  },

  async mounted () {
    const now = new Date();

    if (this.user.token != "") {
      if (now.getTime() - new Date(this.personaldatenLastUpdate).getTime() > this.personaldatenLastUpdateInterval) {
        this.store.dispatch(PersonalActionTypes.GetPersonaldaten, undefined)
        .then(() => {
          document.title = this.personaldaten.archivmandant
        })
      }
    }
  },
})
