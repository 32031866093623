
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import NameUndAdresseEditModalEN from "../Modals/NameUndAdresseEditModal_EN.vue";

export default defineComponent ({
name: "NameUndAdresseEditVorgangEN",
components: {
  NameUndAdresseEditModalEN
},

props: {
  index: {
    type: Number,
    default: 2
  }
},

setup () {
  const nameUndAdresseModalENRef = ref();

  function showModal() {
    nameUndAdresseModalENRef.value?.showModal()
  }

  return {
    nameUndAdresseModalENRef,
    showModal
  }
},


})
