
import { defineComponent, computed, PropType } from "vue";

import { useStore } from '@/store'

import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels";

export default defineComponent({
  name: "PostfachNachricht",
  
  props: {
    nachricht: {
      type: Object as PropType<PostfachNachricht>,
      default: new PostfachNachricht()
    }
  },

  setup(props) {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const adateConverter = computed(() => {
      const adate = new Date(props.nachricht.adate);
      return ("0" + adate.getDate()).slice(-2) + "." + ("0" + (adate.getMonth() + 1)).slice(-2) + "." + adate.getFullYear() + " " + ("0" + (adate.getHours())).slice(-2) + ":" + ("0" + (adate.getMinutes())).slice(-2);
    })

    return {
      language,
      adateConverter
    }
  }
})
