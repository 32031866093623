
import { computed, ref, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { Modal } from 'bootstrap';

import { ArchivDokument, FolderDocsSearchParams, GetFolderDocsRequest } from "@/models/Archiv/ArchivModels";
import { MultipleDocsRequest, ArchivDocRequest } from "@/models/Personal/PersonalModels";


import OrdnerDokument from "@/components/Ordner/OrdnerDokument.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue"

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PAGESIZE: number = config.ordnerPageSize

export default defineComponent({
  name: "Ordner",
  components: {
    OrdnerDokument,
    LoadingSpinner
  },

  props: {
    folderid: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const oldFolderid = props.folderid;

    const displayPage = ref(1)

    const page = ref(1)
    const searchString = ref("")
    const filter = ref("")

    const personaldaten = computed(() => store.getters.personaldaten)
    const user = computed(() => store.getters.user);
    const archivUser = computed(() => store.getters.archivUser);
    const loading = computed(() => store.getters.status.archivFolderDocsLoading)
    const language = computed(() => store.getters.language);


    const folder = computed(() => store.getters.folder.find(f => f.id.toString() == props.folderid));
    const foldertitel = computed(() => {
      if (folder.value && language.value == "de_DE" && folder.value?.foldertitleDe != null && folder.value?.foldertitleDe != "") {
        return folder.value?.foldertitleDe
      }
      else if (folder.value && language.value == "en_GB" && folder.value?.foldertitleEn != null && folder.value?.foldertitleEn != "") {
        return folder.value?.foldertitleEn
      }
      else {
        return folder.value?.foldertitle || "Dokumente"
      }
    })

    const folderData = computed(() => {
      const data = store.getters.folderData.find((f: FolderDocsSearchParams) => f.folderid == parseInt(props.folderid))
      
      if (data != null) {
        page.value = data.page != null ? data.page : 1;
        searchString.value = data.searchString;
      }

      return data;
    })

    const sortByValue = ref("datumdesc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const sorteddocs = computed(() => {
      return folder.value?.dokumente
      .filter(doc => doc.titel.toLowerCase().includes(filter.value.toLowerCase()))
      .sort((a, b) => {
        if (sortByValue.value == "titeldesc" || sortByValue.value == "titelasc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }
          
          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }
          
          return titleComparison
        }

        else if (sortByValue.value == "datumdesc" || sortByValue.value == "datumasc") {
          if (sortByValue.value == "datumasc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1
          }
          else if (sortByValue.value == "datumdesc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1
          }
          else {
            return 0
          }
        }

        return 0
      })
      .slice((displayPage.value - 1) * PAGESIZE, ((displayPage.value - 1) * PAGESIZE) + PAGESIZE)
    })

    const terminalversion = computed(() => store.getters.terminalVersion)

    const selectedDocs = ref(new Array<ArchivDokument>())
    const selectAll = ref(false)

    function checkIfAllDocsSelected() {
      if (selectedDocs.value.length == folder.value?.dokumente.length) {
        selectAll.value = true
      }
      else {
        selectAll.value = false
      }
    }

    function selectAllDocs() {
      if (selectAll.value && folder.value != null && folder.value.dokumente != null) {
        selectedDocs.value = folder.value.dokumente
      }
      else {
        selectedDocs.value = new Array<ArchivDokument>();
      }

    }

    const privateEmail = ref(personaldaten.value.email)

    //#region Download as Zip
    const getDocsLoading = computed(() => store.getters.status.getDocsLoading)
    const getDocsErrorMsg = computed(() => store.getters.status.getDocsErrorMsg)
    const zipDownload = computed(() => store.getters.zipDownload)

    const getDocsAsZipModalRef = ref();
    function closeGetDocsAsZipModal() { Modal.getInstance(getDocsAsZipModalRef.value)?.hide(); }
    function showGetDocsAsZipModal() {
      if (getDocsAsZipModalRef.value) {
        new Modal(getDocsAsZipModalRef.value).show();
      }
    }

    function getCurrentDateAsString() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so we add 1
      const day = today.getDate().toString().padStart(2, '0');
      
      return `${year}${month}${day}`;
  }

    function downloadSelectedDocs() {
      const docs = new Array<ArchivDocRequest>();
      for (var doc of selectedDocs.value) {
        docs.push(new ArchivDocRequest(doc.componente.filename, doc.componente.compid, doc.docid, personaldaten.value.archivmandant, personaldaten.value.archivname))
      }
      const data = new MultipleDocsRequest(docs, store.getters.archivUser.token, privateEmail.value);
  
      store.dispatch(PersonalActionTypes.GetMultipleDocsAsZip, data)
      .then(() => {
        if (getDocsErrorMsg.value == "") {
          // Create a temporary URL object for the blob
          const url = window.URL.createObjectURL(zipDownload.value);

          let today = getCurrentDateAsString()
          let filename = "dokumente-" + today + ".zip"
          if (folder.value != null && language.value == "en_GB" && folder.value.foldertitleEn != "") {
            filename = folder.value?.foldertitleEn + "-" + today +  ".zip"
          } 
          else if (folder.value != null && folder.value.foldertitleDe != "") {
            filename = folder.value?.foldertitleDe + "-" + today +  ".zip"
          }

          // Create a temporary anchor element
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // specify the filename you want the user to download it as

          // Append the anchor to the document body
          document.body.appendChild(a);

          // Programmatically trigger a click event on the anchor
          a.click();

          // Cleanup: remove the anchor and revoke the URL object to release memory
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          selectedDocs.value = new Array<ArchivDokument>();
          selectAll.value = false;
          closeGetDocsAsZipModal();
        }
      })
    }
    //#endregion

    // #region SENDEMAIL
    const emailLoading = computed(() => store.getters.status.sendEmailLoading)
    const emailErrorMsg = computed(() => store.getters.status.sendEmailErrorMsg)

    const sendMultipleDocsEmailModalRef = ref();
    function closeEmailModal() { Modal.getInstance(sendMultipleDocsEmailModalRef.value)?.hide(); }
    function showEmailModal() {
      if (sendMultipleDocsEmailModalRef.value) {
        new Modal(sendMultipleDocsEmailModalRef.value).show();
      }
    }

    function sendSelectedDocs() {
      const docs = new Array<ArchivDocRequest>();
      for (var doc of selectedDocs.value) {
        docs.push(new ArchivDocRequest(doc.componente.filename, doc.componente.compid, doc.docid, personaldaten.value.archivmandant, personaldaten.value.archivname))
      }
      const data = new MultipleDocsRequest(docs, store.getters.archivUser.token, privateEmail.value);
  
      store.dispatch(PersonalActionTypes.SendMultipleDocsAsMail, data)
      .then(() => {
        if (emailErrorMsg.value == "") {
          selectedDocs.value = new Array<ArchivDokument>();
          selectAll.value = false;
          closeEmailModal();
        }
      })
    }
    //#endregion

    //#region Print Multiple Docs
    function printSelectedDocs() {
      console.log("printSelectedDocs");
    }
    //#endregion

    
    return {
      store,
      displayPage,
      page,
      filter,
      searchString,
      folderData,
      user,
      archivUser,
      loading,
      language,
      oldFolderid,
      folder,
      foldertitel,
      sorteddocs,
      // dokumentarten,
      // name,

      sortByValue,
      sortBy,

      terminalversion,
      selectedDocs,
      selectAll,
      checkIfAllDocsSelected,
      selectAllDocs,
      downloadSelectedDocs,

      privateEmail,

      getDocsLoading,
      getDocsErrorMsg,
      zipDownload,
      getDocsAsZipModalRef,
      closeGetDocsAsZipModal,
      showGetDocsAsZipModal,

      emailLoading,
      emailErrorMsg,
      sendMultipleDocsEmailModalRef,
      closeEmailModal,
      showEmailModal,
      sendSelectedDocs,

      printSelectedDocs,
    }
  },

  methods: {
    getFolderDocuments () {
      if (this.folder && (this.folder?.dokumente == null || this.folder?.dokumente?.length == 0) && !this.loading) {
        this.page = 1;
        this.displayPage = 1;
        this.searchString = "";

        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
        this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
      }
    },

    searchFolderDocuments () {
      if (this.folder) {
        if (this.searchString.trim() == "") {
          this.page = 1;
          this.displayPage = 1;
          const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
          this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
        }
        else {
          const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), null, null)
          this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
        }
      }
    },

    loadNextPage() {
      if (this.folder && (this.page == null || (this.page == 1 && this.folder.dokumente.length > PAGESIZE))) {
        this.page = Math.trunc(this.folder.dokumente.length / PAGESIZE)
      }

      this.page++;

      if (this.folder) {
        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
        this.store.dispatch(ArchivActionTypes.LoadFolderDocsPage, request)
      }
    },

    goToPrevPage() {
      if (this.displayPage > 1) {
        this.selectedDocs = new Array<ArchivDokument>();
        this.selectAll = false
        this.displayPage--;
      }
    },

    goToNextPage() {
      if (!this.folderData?.allDocsLoaded && this.page == this.displayPage) {
        this.loadNextPage();
      }
      this.displayPage++;
      this.selectAll = false
      this.selectedDocs = new Array<ArchivDokument>();
    },
    
    updateList () {
      if (this.folder && !this.loading) {
        this.filter = "";
        this.selectedDocs = new Array<ArchivDokument>();
        this.selectAll = false
        this.displayPage = 1;

        // if (this.folderData != null) {
        //   this.page = this.folderData.page != null ? this.folderData.page : 1;
        //   this.searchString = this.folderData.searchString;
        // }

        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), 1, this.page * PAGESIZE)
        this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
      }
    },
  },

  mounted () {
    // this.name = decodeURIComponent(this.$route.params['foldertitel'] as string);

    if (this.folderData != null) {
      this.page = this.folderData.page != null ? this.folderData.page : 1;
      this.searchString = this.folderData.searchString;
    }

    this.getFolderDocuments();

    const searchInput = document.getElementById("searchInput") as HTMLInputElement
    searchInput?.addEventListener("search", () => {
      if (searchInput.value === '') {
        this.searchFolderDocuments();
      }
    })
  },
  
  beforeUpdate () {
    if (this.oldFolderid != this.folderid) {
      this.oldFolderid = this.folderid
      this.filter = "";
      this.page = 1;
      this.displayPage = 1;
      this.searchString = "";

      if (this.folderData != null) {
        this.page = this.folderData.page != null ? this.folderData.page : 1;
        this.searchString = this.folderData.searchString;
      }

      this.getFolderDocuments();
    }
  }
})
