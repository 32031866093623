
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { BankverbindungEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "BankverbindungEditModal",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => {
      setIban(store.getters.personaldaten.iban)
      return store.getters.personaldaten
    });

    const setIban = (ibanString: string) => {
      let iban = ibanString.replace(/\s/g, '').toUpperCase();

      var ibanSplit = iban.match(/.{1,4}/g)
      if (ibanSplit != null) {
        data.iban0 = ibanSplit[0] || "";
        data.iban1 = ibanSplit[1] || "";
        data.iban2 = ibanSplit[2] || "";
        data.iban3 = ibanSplit[3] || "";
        data.iban4 = ibanSplit[4] || "";
        data.iban5 = ibanSplit[5] || "";
        data.iban6 = ibanSplit[6] || "";
        data.iban7 = ibanSplit[7] || "";
        data.iban8 = ibanSplit[8] || "";
      }
    }

    const privateIban = computed(() => {
      var ibanString = (data.iban0 + data.iban1 + data.iban2 + data.iban3 + data.iban4 + data.iban5 + data.iban6 + data.iban7 + data.iban8).toUpperCase()
      var iban = ibanString.replace(/\s/g, '').toUpperCase();

      if (iban.length > 2 && !ibanRegex.test(iban)) {
        ibanError.value = true;
      }
      else {
        ibanError.value = false;
      }

      de_iban.value = iban.length < 2 || iban.startsWith("DE")
      if (de_iban.value) {
        data.iban5 = data.iban5.slice(0,2)
        data.iban6 = "";
        data.iban7 = "";
        data.iban8 = "";
      }

      return iban
    });

    const data = reactive({
      iban0: "",
      iban1: "",
      iban2: "",
      iban3: "",
      iban4: "",
      iban5: "",
      iban6: "",
      iban7: "",
      iban8: "",
    })
    
    const ibanRegex = /^[A-Z]{2}[0-9]{1,32}$/;
    const ibanError = ref(false)
    const de_iban = ref(false)

    const state = reactive({
      bankname: personaldaten.value.bankname,
      bic: personaldaten.value.bic,
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    const resetForm = () => {
      state.bankname = personaldaten.value.bankname;
      state.bic = personaldaten.value.bic;
      state.responseEmailAdresse = personaldaten.value.email;
      state.postfachartid = 1;
      errorMessage.value = "";
    }

    let errorMessage = ref("");

    const bankverbindungEditModalRef = ref();
    const closeModal = () =>  {
      resetForm()
      setIban(personaldaten.value.iban)
      Modal.getInstance(bankverbindungEditModalRef.value)?.hide();
    }
    const showModal = () => {
      if (bankverbindungEditModalRef.value) {
        new Modal(bankverbindungEditModalRef.value).show();
      }
    }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      bankverbindungEditModalRef,
      closeModal,
      showModal,
      ibanError,
      de_iban,
      privateIban,
      ...toRefs(data),
      setIban
    }
  },

  data () {
    return {
      wrapper: "",
      el: ""
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: BankverbindungEditDataRequest = {
        bankname: "",
        iban: this.privateIban,
        bic: this.de_iban ? "" : this.bic,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }


      this.store.dispatch(PersonalActionTypes.UpdateBankverbindung, data)
        .then(() => {
          
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    },

    clearIbanInput () {
      this.iban0 = "";
      this.iban1 = "";
      this.iban2 = "";
      this.iban3 = "";
      this.iban4 = "";
      this.iban5 = "";
      this.iban6 = "";
      this.iban7 = "";
      this.iban8 = "";
    },

    autoSelectText(e: FocusEvent) {
      let el = e.target as HTMLInputElement;
      el.select();
    },

    pasteEvent(e: ClipboardEvent) {
      e.stopPropagation();
      e.preventDefault();

      // Get pasted data via clipboard API
      var pastedData = e.clipboardData?.getData('Text');

      this.setIban(pastedData || "");
    },

    checkInputFirst(e: KeyboardEvent) {
      const regex = /^[A-Za-z0-9]+$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    },

    checkInputNumber(e: KeyboardEvent) {
      const regex = /^[0-9]+$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    },

    setIbanInputEventListener () {
      const wrapper = document.getElementById("ibanInputFields") as HTMLElement | null
      const el = wrapper?.querySelectorAll(".ibanInputs") as NodeListOf<HTMLInputElement>

      Array.prototype.forEach.call(el, (e: HTMLInputElement, index: number) => {
        e.addEventListener("focus", this.autoSelectText);
        e.addEventListener("paste", this.pasteEvent);

        if (index == 0) {
          e.addEventListener("keydown", this.checkInputFirst);
        }
        else {
          e.addEventListener("keydown", this.checkInputNumber);
        }

        // Auto jump to next input if 4 chars in input
        e.addEventListener("keyup", (e: KeyboardEvent) => {
          if (!(e.key == "ArrowLeft" || e.key == "ArrowRight")) {
            const target = e.target as HTMLInputElement | null;
            
            if (target) {
              const maxlength = 4;
              const inputLength = target.value.length;
              
              if (!(target.selectionStart == 0 && target.selectionEnd == target.value.length)) {
                if (maxlength === inputLength && index < (el.length - 1)) {
                  el[index + 1].focus();
                }
              }
            }
          }
        });
      })
    }
  },

  mounted () {
    this.setIbanInputEventListener()
  }

})
