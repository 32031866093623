
import { defineComponent, ref } from "vue";
import KrankenkasseEditModalEN from "../Modals/KrankenkasseEditModal_EN.vue";

export default defineComponent ({
  name: "KrankenkasseEditVorgangEN",
  components: {
    KrankenkasseEditModalEN
  },

  setup () {
    const krankenkasseModalENRef = ref();

    function showModal() {
      krankenkasseModalENRef.value?.showModal()
    }

    return {
      krankenkasseModalENRef,
      showModal
    }
  },
})
