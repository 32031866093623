
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { KrankenkasseEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "KrankenkasseEditModal_EN",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      krankenkasse: personaldaten.value.krankenkasse,
      ort: "",
      datum: (new Date()).toISOString().split('T')[0],
      message: "",
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.krankenkasse = personaldaten.value.krankenkasse,
      state.ort = "",
      state.datum = (new Date()).toISOString().split('T')[0],
      state.message = "",
      state.responseEmailAdresse = personaldaten.value.email,
      state.postfachartid = 1
      errorMessage.value = "";
    }

    const krankenkasseModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(krankenkasseModalRef.value)?.hide();
    }
    const showModal = () => {
    if (krankenkasseModalRef.value) {
      new Modal(krankenkasseModalRef.value).show();
    }
  }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      krankenkasseModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: KrankenkasseEditDataRequest = {
        krankenkasse: this.krankenkasse,
        ort: this.ort,
        datum: new Date(this.datum),
        message: this.message,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }

      this.store.dispatch(PersonalActionTypes.UpdateKrankenkasse, data)
        .then(() => {
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    }
  }

})
