
import { computed, defineComponent } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import PostfachGesendetNachricht from "@/components/Postfach/PostfachGesendetNachricht.vue";

export default defineComponent({
  name: "PostfachGesendet",
  components: {
    PostfachGesendetNachricht
  },

  setup () {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const gesendet = computed(() => store.getters.postfachGesendet)
    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)

    return {
      store,
      language,
      gesendet,
      postfachLastUpdate,
      postfachLastUpdateInterval,
    }
  },

  mounted () {
    const now = new Date();

    if (now.getTime() -  new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
  }
});
