import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Profil from '../views/Profil.vue'
import Ordner from '../views/Ordner.vue'
import Vorgaenge from '../views/Vorgaenge.vue'
import Postfach from '../views/Postfach.vue'
import PostfachGesendet from '../views/PostfachGesendet.vue'
import Terminal from '../views/Terminal.vue'

// import Login from '@/views/Login.vue'
// mach den import so:
// const Login = () => import (/* webpackChunkName: "login" */  '@/views/Login.vue')
// const Logout = () => import (/* webpackChunkName: "Logout" */ '@/views/Logout.vue')
// const Profil = () => import (/* webpackChunkName: "Profil" */ '@/views/Profil.vue')
// const DokumentId = () => import (/* webpackChunkName: "DokumentId" */ '@/views/DokumentId.vue')
// const Ordner = () => import (/* webpackChunkName: "Ordner" */ '@/views/Ordner.vue')
// const Vorgaenge = () => import (/* webpackChunkName: "Vorgaenge" */ '@/views/Vorgaenge.vue')
// const Postfach = () => import (/* webpackChunkName: "Postfach" */ '@/views/Postfach.vue')
// const PostfachGesendet = () => import (/* webpackChunkName: "PostfachGesendet" */ '@/views/PostfachGesendet.vue')
// const Terminal = () => import (/* webpackChunkName: "Terminal" */ '@/views/Terminal.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Profil',
    component: Profil
  },
  {
    path: '/ordner/:folderid',
    name: 'Ordner',
    component: Ordner,
    props: true
  },
  {
    path: '/vorgaenge',
    name: 'Vorgaenge',
    component: Vorgaenge
  },
  {
    path: '/postfach',
    name: 'Postfach',
    component: Postfach
  },
  {
    path: '/postfach/gesendet',
    name: 'PostfachGesendet',
    component: PostfachGesendet
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/terminal',
    name: 'Terminal',
    component: Terminal
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);
  // const store = useStore();
  // const personalLoggedIn = store.getters.status.personalLoggedIn

  let loggedIn = false;

  if ((localStorage.getItem('user') || sessionStorage.getItem('user'))) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next();
});

export default router
