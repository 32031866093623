import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1824e3b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "d-inline fs-4"
}
const _hoisted_3 = {
  key: 1,
  class: "d-inline fs-4"
}
const _hoisted_4 = { id: "postfachAccordion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostfachGesendetNachricht = _resolveComponent("PostfachGesendetNachricht")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.language == 'en_GB')
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Sent Messages"))
        : (_openBlock(), _createElementBlock("h1", _hoisted_3, "Gesendete Nachrichten"))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gesendet, (nachricht) => {
        return (_openBlock(), _createBlock(_component_PostfachGesendetNachricht, {
          key: nachricht.id,
          nachricht: nachricht
        }, null, 8, ["nachricht"]))
      }), 128))
    ])
  ], 64))
}