import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-content-between",
  id: "main-content"
}
const _hoisted_2 = { class: "flex-fill flex-column" }
const _hoisted_3 = {
  key: 3,
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_AutoLogout = _resolveComponent("AutoLogout")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Sidebar_rechts = _resolveComponent("Sidebar_rechts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isPublicPage && _ctx.loggedIn)
      ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isPublicPage && _ctx.loggedIn)
        ? (_openBlock(), _createBlock(_component_Navbar, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.isPublicPage && _ctx.loggedIn)
        ? (_openBlock(), _createBlock(_component_AutoLogout, { key: 1 }))
        : _createCommentVNode("", true),
      (!_ctx.isPublicPage && (_ctx.loginLoading || _ctx.logoutLoading))
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 2,
            text: '... Daten werden geladen ...'
          }, null, 8, ["text"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ]))
    ]),
    (!_ctx.isPublicPage && _ctx.loggedIn)
      ? (_openBlock(), _createBlock(_component_Sidebar_rechts, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}