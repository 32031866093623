
import { defineComponent, computed, ref } from "vue";
import { useStore } from '@/store'

import KontaktinformationenEditVorgang from "@/components/Vorgaenge/Vorgang/KontaktinformationenEditVorgang.vue";
import KontaktinformationenEditVorgang_EN from "@/components/Vorgaenge/Vorgang/KontaktinformationenEditVorgang_EN.vue";
import NameUndAdresseEditVorgang from "@/components/Vorgaenge/Vorgang/NameUndAdresseEditVorgang.vue";
import NameUndAdresseEditVorgang_EN from "@/components/Vorgaenge/Vorgang/NameUndAdresseEditVorgang_EN.vue";
import BankverbindungEdit from "@/components/Vorgaenge/Vorgang/BankverbindungEditVorgang.vue";
import BankverbindungEdit_EN from "@/components/Vorgaenge/Vorgang/BankverbindungEditVorgang_EN.vue";
import NachrichtAnHR from "@/components/Vorgaenge/NachrichtAnHR.vue";
import NachrichtAnHR_EN from "@/components/Vorgaenge/NachrichtAnHR_EN.vue";
import DocAnHR from "@/components/Vorgaenge/DocAnHR.vue";
import DocAnHR_EN from "@/components/Vorgaenge/DocAnHR_EN.vue";
import AntragAufElternzeit from "@/components/Vorgaenge/AntragAufElternzeit.vue";
import AntragAufElternzeit_EN from "@/components/Vorgaenge/AntragAufElternzeit_EN.vue";
import Nebentaetigkeit from "@/components/Vorgaenge/Nebentaetigkeit.vue";
import Nebentaetigkeit_EN from "@/components/Vorgaenge/Nebentaetigkeit_EN.vue";
import TeilnahmeerklaerungAltersvorsorge from "@/components/Vorgaenge/TeilnahmeerklaerungAltersvorsorge.vue";
import TeilnahmeerklaerungAltersvorsorge_EN from "@/components/Vorgaenge/TeilnahmeerklaerungAltersvorsorge_EN.vue";
import Bescheinigung from "@/components/Vorgaenge/Bescheinigung.vue";
import Bescheinigung_EN from "@/components/Vorgaenge/Bescheinigung_EN.vue";
import AntragBildschirmbrille from "@/components/Vorgaenge/AntragBildschirmbrille.vue";
import AntragBildschirmbrille_EN from "@/components/Vorgaenge/AntragBildschirmbrille_EN.vue";
import KrankenkasseEdit from "@/components/Vorgaenge/Vorgang/KrankenkasseEditVorgang.vue";
import KrankenkasseEdit_EN from "@/components/Vorgaenge/Vorgang/KrankenkasseEditVorgang_EN.vue";
import SEPALastschriftMandat from "@/components/Vorgaenge/SEPALastschriftMandat.vue";
import SEPALastschriftMandat_EN from "@/components/Vorgaenge/SEPALastschriftMandat_EN.vue";

export default defineComponent({
  name: "Vorgaenge",

  components: {
    KontaktinformationenEditVorgang,
    KontaktinformationenEditVorgang_EN,
    NameUndAdresseEditVorgang,
    NameUndAdresseEditVorgang_EN,
    BankverbindungEdit,
    BankverbindungEdit_EN,
    NachrichtAnHR,
    NachrichtAnHR_EN,
    DocAnHR,
    DocAnHR_EN,
    AntragAufElternzeit,
    AntragAufElternzeit_EN,
    Nebentaetigkeit,
    Nebentaetigkeit_EN,
    TeilnahmeerklaerungAltersvorsorge,
    TeilnahmeerklaerungAltersvorsorge_EN,
    Bescheinigung,
    Bescheinigung_EN,
    AntragBildschirmbrille,
    AntragBildschirmbrille_EN,
    KrankenkasseEdit,
    KrankenkasseEdit_EN,
    SEPALastschriftMandat,
    SEPALastschriftMandat_EN,
  },

  setup() {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const editBankverbindungRef = ref<typeof BankverbindungEdit | null>(null)
    const editBankverbindung = () => {
      if (editBankverbindungRef.value) {
        editBankverbindungRef.value.showModal()
      }
    }

    const editBankverbindungENRef = ref<typeof BankverbindungEdit_EN | null>(null)
    const editBankverbindungEN = () => {
      if (editBankverbindungENRef.value) {
        editBankverbindungENRef.value.showModal()
      }
    }


    const editNameUndAdresseRef = ref<typeof NameUndAdresseEditVorgang | null>(null)
    const editNameUndAdresse = () => {
      if (editNameUndAdresseRef.value) {
        editNameUndAdresseRef.value.showModal()
      }
    }

    const editNameUndAdresseENRef = ref<typeof NameUndAdresseEditVorgang_EN | null>(null)
    const editNameUndAdresseEN = () => {
      if (editNameUndAdresseENRef.value) {
        editNameUndAdresseENRef.value.showModal()
      }
    }


    const editKontaktdatenRef = ref<typeof KontaktinformationenEditVorgang | null>(null)
    const editKontaktdaten = () => {
      if (editKontaktdatenRef.value) {
        editKontaktdatenRef.value.showModal()
      }
    }

    const editKontaktdatenENRef = ref<typeof KontaktinformationenEditVorgang_EN | null>(null)
    const editKontaktdatenEN = () => {
      if (editKontaktdatenENRef.value) {
        editKontaktdatenENRef.value.showModal()
      }
    }

    const nachrichtAnHRRef = ref<typeof NachrichtAnHR | null>(null)
    const nachrichtAnHR = () => {
      if (nachrichtAnHRRef.value) {
        nachrichtAnHRRef.value.showModal()
      }
    }

    const nachrichtAnHRENRef = ref<typeof NachrichtAnHR_EN | null>(null)
    const nachrichtAnHREN = () => {
      if (nachrichtAnHRENRef.value) {
        nachrichtAnHRENRef.value.showModal()
      }
    }

    const docAnHRRef = ref<typeof DocAnHR | null>(null)
    const docAnHR = () => {
      if (docAnHRRef.value) {
        docAnHRRef.value.showModal()
      }
    }

    const docAnHRENRef = ref<typeof DocAnHR_EN | null>(null)
    const docAnHREN = () => {
      if (docAnHRENRef.value) {
        docAnHRENRef.value.showModal()
      }
    }

    const antragAufElternzeitRef = ref<typeof AntragAufElternzeit | null>(null)
    const antragAufElternzeit = () => {
      if (antragAufElternzeitRef.value) {
        antragAufElternzeitRef.value.showModal()
      }
    }

    const antragAufElternzeitENRef = ref<typeof AntragAufElternzeit_EN | null>(null)
    const antragAufElternzeitEN = () => {
      if (antragAufElternzeitENRef.value) {
        antragAufElternzeitENRef.value.showModal()
      }
    }

    const nebentaetigkeitRef = ref<typeof Nebentaetigkeit | null>(null)
    const nebentaetigkeit = () => {
      if (nebentaetigkeitRef.value) {
        nebentaetigkeitRef.value.showModal()
      }
    }

    const nebentaetigkeitENRef = ref<typeof Nebentaetigkeit_EN | null>(null)
    const nebentaetigkeitEN = () => {
      if (nebentaetigkeitENRef.value) {
        nebentaetigkeitENRef.value.showModal()
      }
    }

    const teilnahmeerklaerungAltersvorsorgeRef = ref<typeof TeilnahmeerklaerungAltersvorsorge | null>(null)
    const teilnahmeerklaerungAltersvorsorge = () => {
      if (teilnahmeerklaerungAltersvorsorgeRef.value) {
        teilnahmeerklaerungAltersvorsorgeRef.value.showModal()
      }
    }

    const teilnahmeerklaerungAltersvorsorgeENRef = ref<typeof TeilnahmeerklaerungAltersvorsorge_EN | null>(null)
    const teilnahmeerklaerungAltersvorsorgeEN = () => {
      if (teilnahmeerklaerungAltersvorsorgeENRef.value) {
        teilnahmeerklaerungAltersvorsorgeENRef.value.showModal()
      }
    }

    const bescheinigungRef = ref<typeof Bescheinigung | null>(null)
    const bescheinigung = () => {
      if (bescheinigungRef.value) {
        bescheinigungRef.value.showModal()
      }
    }

    const bescheinigungENRef = ref<typeof Bescheinigung_EN | null>(null)
    const bescheinigungEN = () => {
      if (bescheinigungENRef.value) {
        bescheinigungENRef.value.showModal()
      }
    }
    
    const antragBildschirmbrilleRef = ref<typeof AntragBildschirmbrille | null>(null)
    const antragBildschirmbrille = () => {
      if (antragBildschirmbrilleRef.value) {
        antragBildschirmbrilleRef.value.showModal()
      }
    }

    const antragBildschirmbrilleENRef = ref<typeof AntragBildschirmbrille_EN | null>(null)
    const antragBildschirmbrilleEN = () => {
      if (antragBildschirmbrilleENRef.value) {
        antragBildschirmbrilleENRef.value.showModal()
      }
    }
    
    const editKrankenkasseRef = ref<typeof KrankenkasseEdit | null>(null)
    const editKrankenkasse = () => {
      if (editKrankenkasseRef.value) {
        editKrankenkasseRef.value.showModal()
      }
    }

    const editKrankenkasseENRef = ref<typeof KrankenkasseEdit_EN | null>(null)
    const editKrankenkasseEN = () => {
      if (editKrankenkasseENRef.value) {
        editKrankenkasseENRef.value.showModal()
      }
    }

    const editSEPALastschriftMandatRef = ref<typeof SEPALastschriftMandat | null>(null)
    const editSEPALastschriftMandat = () => {
      if (editSEPALastschriftMandatRef.value) {
        editSEPALastschriftMandatRef.value.showModal()
      }
    }

    const editSEPALastschriftMandatENRef = ref<typeof SEPALastschriftMandat_EN | null>(null)
    const editSEPALastschriftMandatEN = () => {
      if (editSEPALastschriftMandatENRef.value) {
        editSEPALastschriftMandatENRef.value.showModal()
      }
    }

    return {
      language,
      editBankverbindungRef,
      editBankverbindung,
      editBankverbindungENRef,
      editBankverbindungEN,
      editNameUndAdresseRef,
      editNameUndAdresse,
      editNameUndAdresseENRef,
      editNameUndAdresseEN,
      editKontaktdatenRef,
      editKontaktdaten,
      editKontaktdatenENRef,
      editKontaktdatenEN,
      nachrichtAnHRRef,
      nachrichtAnHR,
      nachrichtAnHRENRef,
      nachrichtAnHREN,
      docAnHRRef,
      docAnHR,
      docAnHRENRef,
      docAnHREN,
      antragAufElternzeitRef,
      antragAufElternzeit,
      antragAufElternzeitENRef,
      antragAufElternzeitEN,
      nebentaetigkeitRef,
      nebentaetigkeit,
      nebentaetigkeitENRef,
      nebentaetigkeitEN,
      teilnahmeerklaerungAltersvorsorgeRef,
      teilnahmeerklaerungAltersvorsorge,
      teilnahmeerklaerungAltersvorsorgeENRef,
      teilnahmeerklaerungAltersvorsorgeEN,
      bescheinigungRef,
      bescheinigung,
      bescheinigungENRef,
      bescheinigungEN,
      antragBildschirmbrilleRef,
      antragBildschirmbrille,
      antragBildschirmbrilleENRef,
      antragBildschirmbrilleEN,
      editKrankenkasseRef,
      editKrankenkasse,
      editKrankenkasseENRef,
      editKrankenkasseEN,
      editSEPALastschriftMandatRef,
      editSEPALastschriftMandat,
      editSEPALastschriftMandatENRef,
      editSEPALastschriftMandatEN,
    }
  },

  mounted() {
    if (this.$route.query.edit) {
      const edit = this.$route.query.edit

      if (this.language == 'en_GB') {
        if (edit == "name-adresse") {
          this.editNameUndAdresseEN()
        }
        else if (edit == "bankverbindung") {
          this.editBankverbindungEN()
        }
        else if (edit == "kontaktdaten") {
          this.editKontaktdatenEN()
        }
        else if (edit == "nachricht") {
          this.nachrichtAnHREN()
        }
        else if (edit == "docanhr") {
          this.docAnHREN()
        }
        else if (edit == "antrag-auf-elternzeit") {
          this.antragAufElternzeitEN()
        }
        else if (edit == "nebentaetigkeit") {
          this.nebentaetigkeitEN()
        }
        else if (edit == "teilnahmeerklaerung-altersvorsorge") {
          this.teilnahmeerklaerungAltersvorsorgeEN()
        }
        else if (edit == "bescheinigung") {
          this.bescheinigungEN()
        }
        else if (edit == "antrag-bildschirmbrille") {
          this.antragBildschirmbrilleEN()
        }
        else if (edit == "krankenkasse") {
          this.editKrankenkasseEN()
        }
        else if (edit == "sepalastschriftmandat") {
          this.editSEPALastschriftMandatEN()
        }
      }
      else {
        if (edit == "name-adresse") {
          this.editNameUndAdresse()
        }
        else if (edit == "bankverbindung") {
          this.editBankverbindung()
        }
        else if (edit == "kontaktdaten") {
          this.editKontaktdaten()
        }
        else if (edit == "nachricht") {
          this.nachrichtAnHR()
        }
        else if (edit == "docanhr") {
          this.docAnHR()
        }
        else if (edit == "antrag-auf-elternzeit") {
          this.antragAufElternzeit()
        }
        else if (edit == "nebentaetigkeit") {
          this.nebentaetigkeit()
        }
        else if (edit == "teilnahmeerklaerung-altersvorsorge") {
          this.teilnahmeerklaerungAltersvorsorge()
        }
        else if (edit == "bescheinigung") {
          this.bescheinigung()
        }
        else if (edit == "antrag-bildschirmbrille") {
          this.antragBildschirmbrille()
        }
        else if (edit == "krankenkasse") {
          this.editKrankenkasse()
        }
        else if (edit == "sepalastschriftmandat") {
          this.editSEPALastschriftMandat()
        }
      }

    }
  }
});
