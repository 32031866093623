
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  props: ["text"],

  updated () {
    console.log(this.text);
  }
})
