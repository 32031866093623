
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import { GetProfilbildRequestData, Archivdata } from "@/models/Personal/PersonalModels"

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import { Folder } from "@/models/Archiv/ArchivModels";


export default defineComponent({
  name: "Sidebar",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const language = ref(store.getters.language);
    const newMessagesCount = computed(() => store.getters.postfachPosteingang.filter(p => p.status == 1 || p.status == 0).length);
    const dokumentarten = computed(() => store.getters.dokumentarten);

    const folder = computed(() => store.getters.folder);
    const foldergroups = computed(() => {
      const groups = store.getters.folder.reduce((groups: {[key: string]: Folder[]}, item) => {
        let group = new Array<Folder>();
        
        if (language.value == "de_DE" && item.foldermenuDe != "") {
          group = (groups[item.foldermenuDe] || []);
          group.push(item);
          groups[item.foldermenuDe] = group;
        }
        else if (language.value == "en_GB" && item.foldermenuEn != "") {
          group = (groups[item.foldermenuEn] || []);
          group.push(item);
          groups[item.foldermenuEn] = group;
        }
        else {
          group = (groups[item.foldermenu] || []);
          group.push(item);
          groups[item.foldermenu] = group;
        }

        return groups;
      }, {});

      return groups
    })
    const folderLoading = computed(() => store.getters.status.archivFolderLoading)
    const dokumentartenLoading = computed(() => store.getters.status.archivDokumentartenLoading)
    const personaldaten = computed(() => store.getters.personaldaten)
    const archivdaten = computed(() => store.getters.archivdaten)
    const archivUser = computed(() => store.getters.archivUser)
    const dokumentartenLastUpdate = computed(() => store.getters.dokumentartenLastUpdate)
    const dokumentartenLastUpdateInterval = computed(() => store.getters.dokumentartenUpdateInterval)
    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)
    const folderLastUpdate = computed(() => store.getters.folderLastUpdate)
    const folderLastUpdateInterval = computed(() => store.getters.folderUpdateInterval)
    const terminalversion = computed(() => store.getters.terminalVersion)
    const status = computed(() => store.getters.status)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      language,
      newMessagesCount,
      dokumentarten,
      folder,
      foldergroups,
      folderLoading,
      dokumentartenLoading,
      personaldaten,
      archivdaten,
      archivUser,
      dokumentartenLastUpdate,
      dokumentartenLastUpdateInterval,
      postfachLastUpdate,
      postfachLastUpdateInterval,
      folderLastUpdate,
      folderLastUpdateInterval,
      terminalversion,
      status,
      sidebarCollapsed
    }
  },

  methods: {
    changeLanguage () {
      this.store.dispatch(CommonActionTypes.SetLanguage, this.language)
    },

    toggleSidebar () {
      this.store.dispatch(PersonalActionTypes.ToggleSidebar, undefined);
    },
  },

  mounted () {
    const now = new Date();

    if (this.archivUser == null || this.archivUser.token == "") {
      this.store.dispatch(ArchivActionTypes.ArchivLogin, undefined)
      .then(() => {

        if (this.personaldaten.guid == "") {
          this.store.dispatch(PersonalActionTypes.GetArchivdaten, undefined)
            .then(() => {
              const archivname = this.archivdaten != null ? this.archivdaten.archivname : ""
              const archivmandant = this.archivdaten != null ? this.archivdaten.archivmandant : ""
            
              const data = new GetProfilbildRequestData(archivname, archivmandant, this.archivUser.token);
              this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
            })
        }
        else {
          const data = new GetProfilbildRequestData(this.personaldaten.archivname, this.personaldaten.archivmandant, this.archivUser.token);
          this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
          // this.store.dispatch(ArchivActionTypes.GetDokumentarten, this.archivUser.token)
        }
      })
    }

    if (now.getTime() - new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
    
    if (now.getTime() - new Date(this.folderLastUpdate).getTime() > this.folderLastUpdateInterval) {
      this.store.dispatch(ArchivActionTypes.GetMitarbeiterFolder, undefined)
    }
  }
});
