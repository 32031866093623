import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46c2775f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-bs-target"]
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-8 my-auto" }
const _hoisted_4 = { class: "p-0 m-0 mb-1" }
const _hoisted_5 = { class: "p-0 m-0 small text-muted fw-normal" }
const _hoisted_6 = { class: "col-3 my-auto" }
const _hoisted_7 = { class: "p-0 m-0" }
const _hoisted_8 = ["id", "aria-labelledby"]
const _hoisted_9 = { class: "modal-dialog modal-lg" }
const _hoisted_10 = { class: "modal-content" }
const _hoisted_11 = { class: "modal-header" }
const _hoisted_12 = ["id"]
const _hoisted_13 = {
  key: 0,
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_14 = { class: "modal-body" }
const _hoisted_15 = { class: "modal-footer" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 1,
  type: "button",
  class: "btn btn-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["row mb-3 mx-1 py-1 shadow-sm postfachMessage", {'unread' : _ctx.nachricht.status == 1 || _ctx.nachricht.status == 0}]),
      "data-bs-toggle": "modal",
      "data-bs-target": '#PostfachNachrichtModal-' + _ctx.nachricht.id
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.nachricht.status == 1 || _ctx.nachricht.status == 0)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "envelope",
              class: "text-start fa-lg"
            }))
          : (_ctx.nachricht.status == 2)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: "envelope-open",
                class: "text-start fa-lg"
              }))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 2,
                icon: "circle-question",
                class: "text-start fa-lg"
              }))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.nachricht?.titel), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.nachricht?.absender), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.adateConverter), 1)
      ])
    ], 10, _hoisted_1),
    _createElementVNode("div", {
      class: "modal fade",
      id: 'PostfachNachrichtModal-' + _ctx.nachricht.id,
      tabindex: "-1",
      "aria-labelledby": 'PostfachNachrichtModal-' + _ctx.nachricht.id,
      "aria-hidden": "true",
      "data-bs-backdrop": "static",
      "data-bs-keyboard": "false"
    }, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h5", {
              class: "modal-title",
              id: 'PostfachNachrichtModalLabel-' + _ctx.nachricht.id
            }, _toDisplayString(_ctx.nachricht.titel), 9, _hoisted_12),
            (_ctx.nachricht.status != 1  && _ctx.nachricht.status != 0)
              ? (_openBlock(), _createElementBlock("button", _hoisted_13))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("pre", null, _toDisplayString(_ctx.nachricht.text), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            (_ctx.nachricht.status == 1  || _ctx.nachricht.status == 0)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-primary",
                  "data-bs-dismiss": "modal",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setGelesenStatus()))
                }, [
                  (_ctx.language == 'en_GB')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Message has been read"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_17, "Nachricht gelesen"))
                ]))
              : (_ctx.nachricht.status == 2)
                ? (_openBlock(), _createElementBlock("button", _hoisted_18, [
                    (_ctx.language == 'en_GB')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, "close"))
                      : (_openBlock(), _createElementBlock("span", _hoisted_20, "schließen"))
                  ]))
                : _createCommentVNode("", true)
          ])
        ])
      ])
    ], 8, _hoisted_8)
  ], 64))
}