
import { defineComponent, ref } from "vue";
import NameUndAdresseEditModalEN from "../Modals/NameUndAdresseEditModal_EN.vue";

export default defineComponent ({
  name: "NameUndAdresseEditProfilEN",
  components: {
    NameUndAdresseEditModalEN
  },

  props: {
    index: {
      type: Number,
      default: 1
    }
  },

  setup () {
    const nameUndAdresseModalRef = ref();

    function showModal() {
      nameUndAdresseModalRef.value?.showModal()
    }

    return {
      nameUndAdresseModalRef,
      showModal
    }
  },
})
