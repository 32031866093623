import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-primary m-4",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.warningZone)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Sind Sie noch da? Sie werden in Kürze automatisch abgemeldet... "))
    : _createCommentVNode("", true)
}