
import { defineComponent, computed, ref, reactive, toRefs } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PDFViewer from "@/components/PDFViewer/PDFViewer.vue";

import { useStore } from '@/store'
import { ActionTypes as VorgangActionTypes } from "@/store/modules/Vorgang/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import { SendFile, GetFormularRequest, NachrichtAnHR } from "@/models/Vorgange/VorgangeModels";
import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses'

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const ADOBESIGNVORGANG: boolean = config.adobeSignVorgang
const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

export default defineComponent ({
  name: "SEPALastschriftMandat",
  components: {
    LoadingSpinner,
    PDFViewer
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.vorgangLoading)
    const status = computed(() => store.getters.status)
    const personaldaten = computed(() => store.getters.personaldaten);
    const archivUser = computed(() => store.getters.archivUser);
    const terminalVersion = computed(() => store.getters.terminalVersion)
    
    const gebdateConverter = computed(() => {
      if (personaldaten.value.gebdat == null) {
        return ""
      }

      const today: Date = new Date();
      const gebdate: Date = new Date(personaldaten.value.gebdat.toString());

      if (gebdate.getDate() == today.getDate() && gebdate.getMonth() == today.getMonth() && gebdate.getFullYear() == today.getFullYear()) {
        return "";
      }

      return ("0" + gebdate.getDate()).slice(-2) + "." + ("0" + (gebdate.getMonth() + 1)).slice(-2) + "." + gebdate.getFullYear();
    });

    const state = reactive({
      // betreff: "",
      // message: "",
      responseEmailAdresse: personaldaten.value.email,
      // postfachartid: 1,
      docloaded: false,
      adobeSign: false,
      adobeSignLink: "",
      barcode: "MAPSepaDE",
    })

    let errorMessage = ref("");
    let antrag = computed(() => store.getters.antragForm)

    const sepaLastschriftMandatPdfViewerRef = ref();

    function printPDF () {
      sepaLastschriftMandatPdfViewerRef.value?.printPDF()
    }

    const sepaLastschriftMandatEditModalRef = ref();

    const closeModal = () => {
      state.responseEmailAdresse = personaldaten.value.email;
      errorMessage.value = "";
      state.adobeSign = false;
      state.adobeSignLink = "";
      state.docloaded = false;
      sepaLastschriftMandatPdfViewerRef.value?.clearViewer()
      Modal.getInstance(sepaLastschriftMandatEditModalRef.value)?.hide();
    }
    const showModal = () => {
      if (sepaLastschriftMandatEditModalRef.value) {
        new Modal(sepaLastschriftMandatEditModalRef.value).show();
      }
    }

    return {
      store,
      loading,
      status,
      personaldaten,
      gebdateConverter,
      archivUser,
      ...toRefs(state),
      antrag,
      errorMessage,
      sepaLastschriftMandatEditModalRef,
      closeModal,
      showModal,
      sepaLastschriftMandatPdfViewerRef,
      printPDF,
      terminalVersion
    }
  },

  data () {
    return {
      // docloaded: false,
      docId: "sepa-lastschrif-mandat",
      pdfName: "SEPA_Lastschrift_Mandat.pdf",
      pdfUrl: "",
      pdfViewerOptions: new PDFViewerOptions(),
    }
  },

  methods: {
    async fetchPdfAndCreateFileObject(pdfUrl: string, fileName: string): Promise<File | null> {
      try {
        const response = await fetch(pdfUrl);
        if (!response.ok) {
          return null
        }
        const pdfBlob = await response.blob()
        const file = new File([pdfBlob], fileName, { type: 'application/pdf' })
        return file
      } catch {
        return null
      }
    },

    async saveChanges () {
      this.errorMessage = "";

      // if (this.adobeSign) {
      //   const data: NachrichtAnHR = {
      //     betreff: "Antrag auf Elternzeit - Adobe Sign",
      //     message: "Antrag auf Elternzeit - Adobe Sign",
      //     responseEmailAdresse: this.responseEmailAdresse,
      //     postfachartid: 1
      //   }

      //   this.store.dispatch(VorgangActionTypes.SendNachrichtAnHR, data)
      //     .then(() => {
      //       if (this.status.vorgangErrorMsg != "") {
      //         // this.errorMessage = this.status.commonErrorMsg;
      //         this.errorMessage = "Es ist ein Fehler beim Versenden des Antrags aufgetreten. Bitte probieren Sie es später erneut."
      //       }
      //       else {
      //         this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
      //         this.startAdobeSign();
      //       }
      //     })
      // }
      // else {
      if (this.sepaLastschriftMandatPdfViewerRef) {
        const blobUrl = await this.sepaLastschriftMandatPdfViewerRef?.saveOrDownloadPDF()
        const file = await this.fetchPdfAndCreateFileObject(blobUrl, this.pdfName)
        if (file != null) {
          const sendFileData: SendFile = {
            betreff: "Antrag auf Elternzeit",
            message: "Antrag auf Elternzeit",
            responseEmailAdresse: this.responseEmailAdresse,
            pdfFile: file,
            postfachartid: 1
          }
          
          this.store.dispatch(VorgangActionTypes.SendFormularDoc, sendFileData)
          .then(() => {
            if (this.status.vorgangErrorMsg != "") {
              // this.errorMessage = this.status.commonErrorMsg;
              this.errorMessage = "Es ist ein Fehler beim Versenden des Antrags aufgetreten. Bitte probieren Sie es später erneut."
            }
            else {
              this.closeModal()
              this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
            }
          })

          // }
        }
        else {
          this.errorMessage = "Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut."
        }
      }
    },

    async getAntrag () {
      this.errorMessage = ""
      this.docloaded = false

      const requestData: GetFormularRequest = {
        archivtoken: this.archivUser.token,
        barcode: this.barcode
      }

      this.store.dispatch(VorgangActionTypes.GetFormularDoc, requestData)
      .then(() => {
        if (this.status.vorgangErrorMsg != "") {
          // this.errorMessage = this.status.vorgangErrorMsg;
          this.errorMessage = "Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut."
        }
        
        if (this.antrag != null) {
          // this.adobeSign = ADOBESIGNVORGANG && !this.terminalVersion && this.antrag.titel_1 != "";
          // if (this.adobeSign) {
          //   this.adobeSignLink = this.replaceStringValues(this.antrag.titel_1);
            
          // }
          // else {
            this.pdfUrl = this.base64PDFToBlobUrl(this.antrag.filecontentAsBase64)
            this.pdfName = this.personaldaten.guid + "_SEPA_Lastschrift_Mandat.pdf"
            this.docloaded = true
          // }
        }
      })
    },

    base64PDFToBlobUrl(base64: string) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      const blob =  new Blob( [ arr ], { type: 'application/pdf' } );
      const url = URL.createObjectURL( blob );
      return url;
    },

    // startAdobeSign () {
    //   if (this.adobeSign && this.adobeSignLink != "") {
    //     window.open(this.adobeSignLink, "_blank");
    //     this.closeModal();
    //   }
    // },

    replaceStringValues(link: string): string {
      link = link.replace("<GUID>", this.personaldaten.guid)
      link = link.replace("<NAME>", this.personaldaten.name)
      link = link.replace("<VORNAME>", this.personaldaten.vorname)
      link = link.replace("<STRASSE>", this.personaldaten.strasse)
      link = link.replace("<PLZ>", this.personaldaten.plz)
      link = link.replace("<ORT>", this.personaldaten.ort)
      link = link.replace("<LAND>", this.personaldaten.land)
      link = link.replace("<MAN>", this.personaldaten.mandant)
      link = link.replace("<MANDANT>", this.personaldaten.mandant)
      link = link.replace("<AK>", this.personaldaten.abrkreis)
      link = link.replace("<ABRKREIS>", this.personaldaten.abrkreis)
      link = link.replace("<PNR>", this.personaldaten.persnr)
      link = link.replace("<PERSNR>", this.personaldaten.persnr)
      link = link.replace("<VERTNR>", this.personaldaten.vertnr.toString())
      link = link.replace("<GEBDATE>", this.gebdateConverter)
      link = link.replace("<BARCODE>", `###${this.personaldaten.mandant}/${this.personaldaten.abrkreis}/${this.personaldaten.persnr}/${this.antrag?.personaldocid}`)
      link = link.replace("<IBAN>", this.personaldaten.iban)

      return link;
    }
  },

  mounted () {
    this.errorMessage = ""
    this.pdfViewerOptions.showDownloadOption = false
    this.pdfViewerOptions.showPrintOption = false
    this.pdfViewerOptions.showEmailSendenOption = false
    this.pdfViewerOptions.showScalingOption = true
    this.pdfViewerOptions.showPagesOption = false
    this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE
  }

})
