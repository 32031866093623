
import { computed, defineComponent, PropType, ref, reactive, toRefs, } from "vue";
import { Modal } from 'bootstrap';

import { useStore } from '@/store';

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PDFViewer from "@/components/PDFViewer/PDFViewer.vue";

import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses'
import { SendDocAsMailRequest } from "@/models/Personal/PersonalModels";
import { ArchivDokument, ArchivDonwloadCompData } from "@/models/Archiv/ArchivModels";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

export default defineComponent({
  name: "OrdnerDokument",
  components: {
    LoadingSpinner,
    PDFViewer
  },

  props: {
    dokument: {
      type: Object as PropType<ArchivDokument>,
      required: true
    },
    printOption: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const store = useStore();
    const language = computed(() => store.getters.language)
    const terminal = computed(() => store.getters.terminalVersion)
    const loading = computed(() => store.getters.status.archivCompLoading)
    const emailloading = computed(() => store.getters.status.sendEmailLoading)
    const emailErrorMsg = computed(() => store.getters.status.sendEmailErrorMsg)

    const ddateConverter = computed(() => {
      const ddate = new Date(props.dokument.ddate);
      // return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear() + " " + ("0" + (ddate.getHours() + 1)).slice(-2) + ":" + ("0" + (ddate.getMinutes() + 1)).slice(-2);
      return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear();
    })
    
    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      privateEmail: personaldaten.value.email,
      showError: false
    })

    const archivUser = computed(() => store.getters.archivUser);
    const componente = computed(() => store.getters.componente);

    const pdfViewerRef = ref();

    const ordnerDokumentPDFModalRef = ref();
    const closeModal = () => {
      pdfViewerRef.value?.clearViewer()
      Modal.getInstance(ordnerDokumentPDFModalRef.value)?.hide();
    }
    const showModal = async () => {
      if (ordnerDokumentPDFModalRef.value) {
        new Modal(ordnerDokumentPDFModalRef.value).show();

        // await pdfViewerRef.value.loadPDF()
      }
    }

    const sendPDFEmailModalRef = ref();
    const closeEmailModal = () => Modal.getInstance(sendPDFEmailModalRef.value)?.hide();
    const showEmailModal = () => {
      if (sendPDFEmailModalRef.value) {
        new Modal(sendPDFEmailModalRef.value).show();
      }
    }

    return {
      store,
      language,
      loading,
      terminal,
      personaldaten,
      ...toRefs(state),
      archivUser,
      componente,
      ddateConverter,
      ordnerDokumentPDFModalRef,
      closeModal,
      showModal,
      sendPDFEmailModalRef,
      closeEmailModal,
      showEmailModal,
      emailloading,
      emailErrorMsg,
      pdfViewerRef
    }
  },

  data () {
    return {
      docloaded: false,
      pdfUrl: "",
      pdfViewerOptions: new PDFViewerOptions()
    }
  },

  methods: {
    getComponents () {
      this.showModal()

      const data: ArchivDonwloadCompData = {
        archivmandant: this.personaldaten.archivmandant,
        archivname: this.personaldaten.archivname,
        componente: this.dokument.componente
      };
      
      this.store.dispatch(ArchivActionTypes.ArchivDownloadComponente, data)
        .then(() => {
          this.pdfUrl = this.base64PDFToBlobUrl(this.componente.file.filecontentAsBase64)
          this.docloaded = true
        });
    },

    base64PDFToBlobUrl(base64: string) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      const blob =  new Blob( [ arr ], { type: 'application/pdf' } );
      const url = URL.createObjectURL( blob );
      return url;
    },

    closePdfModal () {
      this.closeModal();

      this.store.dispatch(ArchivActionTypes.ArchivClearComponentenCache, undefined);
    },

    showSendEmailModal() {
      this.showError = false
      this.showEmailModal()
    },

    closeSendEmailModal() {
      this.privateEmail = this.personaldaten.email
      this.closeEmailModal()
    },

    sendmail () {
      const data: SendDocAsMailRequest = {
        archivmandant: this.personaldaten.archivmandant,
        archivname: this.personaldaten.archivname,
        docguid: this.dokument.componente.docid.trim(),
        compguid: this.dokument.componente.compid.trim(),
        filename: this.dokument.componente.filename.trim(),
        archivtoken: this.archivUser.token,
        responseEmailAdresse: this.privateEmail
      };

      this.store.dispatch(PersonalActionTypes.SendDocAsMail, data)
        .then(() => {
          if (this.emailErrorMsg == null || this.emailErrorMsg == "") {
            this.closeEmailModal()
          }
          else {
            this.showError = true
          }
        });
    }
  },

  mounted () {
    this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE
    this.pdfViewerOptions.showPrintOption = this.printOption

    if (this.terminal) {
      this.pdfViewerOptions.showDownloadOption = false
    }
  }
})
