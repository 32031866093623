
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModalEN from "../Modals/KontaktinformationenEditModal_EN.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModalEN
  },

  setup () {
    const kontaktinformationenModalENRef = ref();

    function showModal() {
      kontaktinformationenModalENRef.value?.showModal()
    }

    return {
      kontaktinformationenModalENRef,
      showModal
    }
  },

})
