
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';

import { useStore } from '@/store'
import { LoginExtPersnrRequest, LoginGuidRequest, LoginRequest, LoginAzureRequest } from "@/models/Personal/PersonalModels"
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";


import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "Login",

  components: {
    LoadingSpinner
  },

  setup() {
    const store = useStore();

    const user = ref("")
    const passwd = ref("")

    const terminalVersion = computed(() => store.getters.terminalVersion);
    const prevUrl = store.getters.prevUrl;
    const loading = computed(() => store.getters.status.personalLoginLoading)
    const errorMsg = computed(() => store.getters.status.personalErrorMsg)

    const isdevmode = computed(() => process.env.NODE_ENV == "development")

    const language = ref(store.getters.language)

    const loginButtonDisabled = computed(() => {
      return user.value == "" || passwd.value == ""
    })

    const msalConfig = computed(() => store.getters.msalConfig);
    const msalInstance = computed(() => {
      // let config = msalConfig.value
      // if (config != null) {
      //   config.auth.redirectUri = "http://localhost:8080"
      // }
      // return config != null ?  new PublicClientApplication(config) : null
      return msalConfig.value != null ?  new PublicClientApplication(msalConfig.value) : null
    })
    const azureLoginAvailable = computed(() => {
      if (store.getters.msalConfig != null) {
        displayBenutzerLogin.value = false;
        displayAzureLogin.value = true;
        return true
      }
      else {
        return false
      }
      // return store.getters.status.azureLoginAvailable
    });
    const displayBenutzerLogin = ref(true)
    const displayAzureLogin = ref(false)

    const account = ref<AccountInfo>()
    const loginRequest = {
        scopes: ["User.Read"]
      };

    const handleResponse = (response: AuthenticationResult) => {
      /**
       * To see the full list of response object properties, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
       */

      if (response !== null) {
        let identyToken = response.idToken
        let username = response.account?.username != undefined ? response.account?.username : ""

        const azureLogin: LoginAzureRequest = new LoginAzureRequest(identyToken, username)
        store.dispatch(PersonalActionTypes.LoginAzure, azureLogin)
      }
      // else {
      //   this.selectAccount();
      // }
    }

    const signIn = () => {
      console.log("signIn");
      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */
      if (msalInstance.value != null) {
        msalInstance.value.loginPopup()
        .then((res: any) => {
          handleResponse(res)
        })
        .catch((error: any) => {
          console.error(error);
        });
      }
    }

    const mounted = ref(false)
    watchEffect(() => {
      if (azureLoginAvailable.value && mounted.value && !terminalVersion.value && !isdevmode.value) {
        signIn()
      }
    })

    return {
      store,
      user,
      passwd,
      terminalVersion,
      prevUrl,
      loading,
      errorMsg,
      isdevmode,
      language,
      loginButtonDisabled,
      msalConfig,
      msalInstance,
      azureLoginAvailable,
      displayBenutzerLogin,
      displayAzureLogin,
      account,
      loginRequest,
      signIn,
      mounted
    }
  },

  methods: {
    resetInput () {
      this.user = "";
      this.passwd ="";
    },

    changeLanguage () {
      this.store.dispatch(CommonActionTypes.SetLanguage, this.language)
    },

    login () {
      var loginRequest: LoginRequest = {
        username: this.user,
        userpassword: this.passwd
      }

      if (this.terminalVersion) {
        this.store.dispatch(PersonalActionTypes.TerminalLogin, loginRequest)
      }
      else {
        this.store.dispatch(PersonalActionTypes.Login, loginRequest)
        .then(() => {
          if (this.errorMsg != "") {
            this.resetInput();
          }
        })
      }
    },

    loginGuid (guid: string) {
      var loginGuidRequest: LoginGuidRequest = {
        guid: guid
      }

      this.store.dispatch(PersonalActionTypes.LoginGuid, loginGuidRequest)
    },

    loginExtpersnr (extpersnr: string) {
      var loginExtpersnrRequest: LoginExtPersnrRequest = {
        externepersnr: extpersnr
      }

      this.store.dispatch(PersonalActionTypes.LoginExtPersnr, loginExtpersnrRequest)
    },

    switchToBenutzerLoginForm () {
      this.displayBenutzerLogin = true;
      this.displayAzureLogin = false;
    },

    switchToAzureLogin () {
      this.displayBenutzerLogin = false;
      this.displayAzureLogin = true;
    },


    // selectAccount() {
    //   /**
    //    * See here for more info on account retrieval: 
    //    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    //    */

    //   const currentAccounts = this.msalInstance.getAllAccounts();
    //   if (currentAccounts.length === 0) {
    //       return;
    //   } else if (currentAccounts.length > 1) {
    //       // Add choose account code here
    //       console.warn("Multiple accounts detected.");
    //   } else if (currentAccounts.length === 1) {
    //       let username = currentAccounts[0].username;
    //       console.log(username);
    //       // showWelcomeMessage(username);
    //   }
    // }
    
  },

  mounted () {
    console.log("mounted");
    this.mounted = true;
    if (this.$route.query.lang) {
      this.store.dispatch(CommonActionTypes.SetLanguage, this.$route.query.lang.toString());
    }

    // if (this.terminalVersion || this.prevUrl.includes("ases.phoenixgroup.eu")) {
    if (this.terminalVersion) { 
      console.log("Erweiterter Login");
      if (this.$route.query.username && this.$route.query.userpassword) {
        // console.log("Methode: Username & Passwort");
        this.user = this.$route.query.username.toString();
        this.passwd = this.$route.query.userpassword.toString();
        
        const url = new URL(window.location.href);
        url.searchParams.delete("username");
        url.searchParams.delete("userpassword");
        window.history.replaceState(history.state, '', url.toString());
        
        this.login();
      }
      
      if (this.$route.query.guid) {
        // console.log("Methode: GUID");
        this.loginGuid(this.$route.query.guid.toString())
        const url = new URL(window.location.href);
        url.searchParams.delete("guid");
        window.history.replaceState(history.state, '', url.toString());
      }
      
      if (this.$route.query.externepersnr) {
        // console.log("Methode: Externe Personalnummer");
        this.loginExtpersnr(this.$route.query.externepersnr.toString())
        const url = new URL(window.location.href);
        url.searchParams.delete("externepersnr");
        window.history.replaceState(history.state, '', url.toString());
      }
    }
    else {
      const url = new URL(window.location.href);
      url.searchParams.delete("guid");
      url.searchParams.delete("externepersnr");
      url.searchParams.delete("username");
      url.searchParams.delete("userpassword");
      window.history.replaceState(history.state, '', url.toString());

      console.log("Erweiterte Login Funktionen stehen nicht zur Verfügung.");
    }
  },
});
