
// import appsettings from '../../public/appsettings.json'
import { computed, defineComponent, ref } from "vue";

import { useStore } from '@/store'
import { useRouter } from 'vue-router';
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';



export default defineComponent({
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.user);
    const profilbild = computed(() => store.getters.profilbild);
    const language = computed(() => store.getters.language);
    
    // const prevUrl = store.getters.prevUrl;
    const terminalVersion = computed(() => store.getters.terminalVersion);
    const azureLogin = computed(() => store.getters.status.azureLogin);
    const azureUsername = computed(() => store.getters.azureUsername);
    const msalConfig = computed(() => store.getters.msalConfig);
    const msalInstance = msalConfig.value != null ? new PublicClientApplication(msalConfig.value) : null

    const config = ref<any>()

    const windowSize = computed(() => window.screen.availWidth > 500)

    return {
      store,
      router,
      config,
      user,
      profilbild,
      language,
      terminalVersion,
      azureLogin,
      azureUsername,
      msalConfig,
      msalInstance,
      windowSize
    }
  },

  methods: {
    async logout() {
      // if (this.azureLogin) {
      //   this.signOut()
      //   .then(() => {
      //     this.store.dispatch(PersonalActionTypes.Logout, undefined);
      //     location.reload();
      //   })
      // }
      // else {
        await this.store.dispatch(PersonalActionTypes.Logout, undefined)
        this.router.replace({name: "Logout"})
        // location.reload();
      // }
    },

    logoutMicrosoft() {
      this.signOut()
        .then(() => {
          this.logout()
        })
    },

    closeApplication () {
      window.close();
      // window.open("https://ases.phoenixgroup.eu:8043/SES/applications/sc", "_self")
      // window.open("https://ases.phoenixgroup.eu:8043/SES/html?ClientNo=92", "_self")
      // https://ases.phoenixgroup.eu:8043/SES/applications/sc

      // if (this.azureLogin) {
      //   this.signOut()
      //   .then(() => {
      //     this.store.dispatch(PersonalActionTypes.Logout, undefined);
      //     window.close();

      //   })
      // }
      // else {
        // this.store.dispatch(PersonalActionTypes.Logout, undefined)
        // .then(() => {
        //   window.close();
        // })
      // }
    },

    openZeiterfassung () {
      window.open("https://ases.phoenixgroup.eu:8043/SES/html?security.sso=true", "_blank");
    },

    signOut(): Promise<void> {

      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */

      if (this.msalConfig != null && this.msalInstance != null) {

        const logoutRequest = {
          account: this.msalInstance.getAccountByUsername(this.azureUsername),
          // postLogoutRedirectUri: this.msalConfig.auth.redirectUri,
          // mainWindowRedirectUri: this.msalConfig.auth.redirectUri
        };
        
        return this.msalInstance.logoutPopup(logoutRequest);
      }
      else {
        return new Promise<void>((res, rej) => {
          res();
        })
      }
    },

    async loadAppsettings () {
      const appsettings = await fetch('/appsettings.json')
      this.config = await appsettings.json()
    },
  },

  mounted () {
    this.loadAppsettings()
  },

  beforeMount () {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      window.location.href = window.location.href;
      // location.reload();
    }
  }
})
