
import { computed, defineComponent, ref, render, PropType } from 'vue';
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/web/pdf_viewer.css";
import { SimpleLinkService, DownloadManager } from 'pdfjs-dist/web/pdf_viewer';
import { PDFViewerOptions } from './PDFViewerClasses'
import { Personaldaten } from '@/models/Personal/PersonalModels'
import { Modal } from 'bootstrap';

import SignaturCanvas from './SignaturCanvas.vue';

pdfjsLib.GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url))


export default defineComponent({
  name: "PDFViewer",

  components: {
    SignaturCanvas
  },

  props: {
    options: {
      type: Object as PropType<PDFViewerOptions>,
      default: new PDFViewerOptions()
    },
    personaldaten : {
      type: Object as PropType<Personaldaten>,
      default: null
    },
    pdfUrl: {
      type: String,
      default: ""
    },
    docId: {
      type: String,
      default: ""
    },
    pdfName: {
      type: String,
      default: ""
    },
    persdocid: {
      type: String,
      default: ""
    },
  },

  emits: [
    "sendDocAsEmail"
  ],

  setup (props) {
    // const pdfDoc = ref();
    const pdfDoc: any = undefined;

    const signaturModalRef = ref();
    const closeSignaturModal = () => Modal.getInstance(signaturModalRef.value)?.hide();
    const showSignaturModal = () => {
      if (signaturModalRef.value) {
        (signaturCanvasRef.value as typeof SignaturCanvas).clearCanvas();
        new Modal(signaturModalRef.value).show();
      }
    }

    const signaturCanvasRef = ref();

    return {
      pdfDoc,
      signaturModalRef,
      closeSignaturModal,
      showSignaturModal,
      signaturCanvasRef,
    }
  },

  data () {
    return {
      wrapperWidth: 0,
      wrapperHeight: 0,
      numPages: 0,
      pdfloading: false,
      currentScale: 0,
      scale: 0,
    }
  },

  methods: {
    async zoomIn () {
      if (this.scale + 0.1 < 2) {
        this.scale += 0.1
        await this.updateViewer()
      }
      else {
        this.scale = 2
        await this.updateViewer()
      }
    },
    
    async zoomOut () {
      if (this.scale - 0.1 > 0.1) {
        this.scale -= 0.1;
        await this.updateViewer();
      }
      else {
        this.scale = 0.1;
        await this.updateViewer();
      }
    },

    async updateViewer () {
      this.clearViewer()
      await this.loadPDF()
    },

    transformDate(datum: Date): string {
      if (datum == null) {
        return ""
      }

      const date: Date = new Date(datum.toString());

      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
    },

    async loadPDF() {
      this.pdfloading = true;
      const viewer = document.getElementById("viewer-" + this.docId) as HTMLDivElement;

      try {
        this.pdfDoc = await pdfjsLib.getDocument(this.pdfUrl).promise;
        this.numPages = this.pdfDoc.numPages

        for (let pageNum = 1; pageNum <= this.pdfDoc.numPages; pageNum++) {
          const page = await this.pdfDoc.getPage(pageNum);
          const pdfPageWidth = page.view[2]
          const pdfPageHeight = page.view[3]

          if (this.scale == 0 && this.options.defaultScale != 0) {
            this.scale = this.options.defaultScale
          }
          else {
            if (this.scale == 0) {
              this.scale = Math.min(this.wrapperWidth / pdfPageWidth, this.wrapperHeight / pdfPageHeight);
            }
          }
          
          const viewport = page.getViewport({ scale: this.scale });

          viewer.style.setProperty('--scale-factor',   viewport.scale.toString());
          
          // Create a container for the canvas and text layer
          const pageDiv = document.createElement('div');
          pageDiv.classList.add('page');
          pageDiv.classList.add('border-0');
          pageDiv.classList.add('my-2');
          // pageDiv.style.setProperty('--scale-factor', scaleProp.toString());
          pageDiv.style.height = `${viewport.height}px`;
          pageDiv.style.width = `${viewport.width}px`;

          // Set the --scale-factor CSS variable to match viewport.scale
          viewer.appendChild(pageDiv);
          
          const textLayer = document.createElement('div');
          textLayer.classList.add("textLayer")
          pageDiv.appendChild(textLayer);

          const annotationLayer = document.createElement("div")
          annotationLayer.classList.add("annotationLayer")
          // annotationLayer.style.setProperty('--scale-factor', viewport.scale.toString());
          pageDiv.appendChild(annotationLayer);

          // const annotationEditorLayer = document.createElement("div")
          // annotationEditorLayer.classList.add("annotationEditorLayer")
          // annotationEditorLayer.tabIndex = 0;
          // annotationEditorLayer.hidden = true;
          // annotationEditorLayer.style.setProperty('--scale-factor', viewport.scale.toString());
          // annotationEditorLayer.hidden = true
          // pageDiv.appendChild(annotationEditorLayer);

          const canvasDiv = document.createElement("div")
          canvasDiv.classList.add("canvasWrapper")
          const canvas = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvasDiv.appendChild(canvas)
          pageDiv.appendChild(canvasDiv);

          const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;

          const textLayerDiv = textLayer;
          textLayerDiv.style.height = `${viewport.height}px`;
          textLayerDiv.style.width = `${viewport.width}px`;

          const renderContext = {
            canvasContext,
            viewport
          }
          const renderTask = page.render(renderContext);
          
          const textContent = await page.getTextContent();
          pdfjsLib.renderTextLayer({
            textContentSource: textContent,
            container: textLayerDiv,
            viewport,
            textDivs: [],
          });

          // Render annotations
          const annotationsData = await page.getAnnotations()
          if (annotationsData.length > 0 && this.personaldaten != null) {
            for (var ann of annotationsData) {
              switch (ann.fieldName.toUpperCase()) {
                case "GUID":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.guid})
                  break;
                case "NAME": 
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.name})
                  break;
                case "VORNAME":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.vorname})
                  break;
                case "STRASSE":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.strasse})
                  break;
                case "PLZ":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.plz})
                  break;
                case "ORT":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.ort})
                  break;
                case "LAND":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.land})
                  break;
                case "MANDANT":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.mandant})
                  break;
                case "MAN":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.mandant})
                  break;
                case "ABRKREIS":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.abrkreis})
                  break;
                case "AK":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.abrkreis})
                  break;
                case "PERSNR":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.persnr})
                  break;
                case "PNR":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.persnr})
                  break;
                case "VERTNR":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.vertnr})
                  break;
                case "GEBDAT":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.transformDate( this.personaldaten.gebdat)})
                  break;
                case "DATUM":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.transformDate( new Date())})
                  break;
                case "BARCODE":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: `###${this.personaldaten.mandant}/${this.personaldaten.abrkreis}/${this.personaldaten.persnr}/${this.persdocid}`})
                  break;
                case "SIGNATUR":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: `${this.personaldaten.titel != null && this.personaldaten.titel != "" ? this.personaldaten.titel + " " : ""} ${this.personaldaten.vorname} ${this.personaldaten.name}`})
                  break;
                case "IBAN":
                  this.pdfDoc.annotationStorage.setValue(ann.id, {value: this.personaldaten.iban.replace(/(.{4})/g, '$1 ')})
                  break;
              }
            }
          }

          const downloadManager = new DownloadManager()
          const linkService = new SimpleLinkService()
          const pdfAnnotationLayer =  new pdfjsLib.AnnotationLayer({
            div: annotationLayer,
            page,
            viewport
          })

          await pdfAnnotationLayer.render({
            annotations: annotationsData,
            // renderInteractiveForms: true,
            linkService,
            downloadManager,
            annotationStorage: this.pdfDoc.annotationStorage
          })

          // const annotationEditorUiManager = new pdfjsLib.AnnotationEditorUIManager(null, null, this.pdfDoc, null)
          // console.log(annotationEditorUiManager);

          // const pdfAnnotationEditorLayer = new pdfjsLib.AnnotationEditorLayer({
          //   uiManager: annotationEditorUiManager,
          //   div: annotationEditorLayer,
          //   pageIndex: pageNum - 1,
          //   viewport,
          //   annotationLayer: pdfAnnotationLayer
          // })

          // await pdfAnnotationEditorLayer.render({
          //   viewport,
          //   annotations: null
          // })

          await renderTask.promise;
          this.pdfloading = false;
        }
      } catch (error) {
        this.pdfloading = false;
        console.error('Error loading the PDF:', error);
      }
    },

    async saveInFileSystem() {
      const blobUrl = await this.saveOrDownloadPDF()
      const a = document.createElement("a");
      if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
      }
      a.href = blobUrl;
      a.target = "_parent";
      if ("download" in a) {
        a.download = this.pdfName;
      }
      (document.body || document.documentElement).append(a);
      a.click();
      a.remove();
    },

    async printPDF() {
      const blobUrl = await this.saveOrDownloadPDF();
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = blobUrl

      const viewer = document.getElementById("viewer-" + this.docId) as HTMLDivElement;
      viewer.appendChild(iframe)
      
      iframe.onload = () => {
        iframe.contentWindow?.print()
      }
    },

    async sendAsEmail () {
      this.$emit("sendDocAsEmail")
    },

    async saveOrDownloadPDF () {
      let blob = new Blob()
      if (this.pdfDoc.annotationStorage.size == 0) {
        blob = await this.downloadPDF()
      }
      else {
        blob = await this.savePDF()
      }

      const blobUrl = URL.createObjectURL(blob);
      return blobUrl
    },

    async downloadPDF () {
      const data = await this.pdfDoc.getData();
      const blob = new Blob([data], {
        type: "application/pdf"
      });

      return blob;
    },

    async savePDF () {
      const data = await this.pdfDoc.saveDocument();
      const blob = new Blob([data], {
        type: "application/pdf"
      });

      return blob;

    },

    triggerInsertSignatur() {
      if (this.signaturCanvasRef) {
        (this.signaturCanvasRef as typeof SignaturCanvas).extractDrawingWithoutBackground();
      }
    },

    async insertSignaturImg (data: any) {
      console.log(data)
      console.log(pdfjsLib)

      console.log(this.pdfDoc.annotationEditorLayer)

      // const stampAnnot = new pdfjsLib.AnnotationEditorLayer.AnnotationEditor();

      
      const x = 496;
      const y = 386; 
      const stampAnnotation: any = {
        annotationType: 13,
        pageIndex: 1,
        bitmapUrl: data,
        rect: [
          x,
          y,
          x + 128,
          y + 32
        ],
        rotation: 0,
      }
      // pdfjsLib.AnnotationEditorLayer.add(stampAnnotation)
      // pdfjsLib.AnnotationEditorLayer.addEditorAnnotation(stampAnnotation);

      // console.log(stampAnnot)
      // console.log(this.pdfDoc)

      // // Get the first page of the document
      // // const page = this.pdfDoc.numPages[0];
      // const page = await this.pdfDoc.getPage(1)
      // console.log(page);

      // // Fetch the image
      // const imageBytes = await fetch(data).then(res => res.arrayBuffer());

      // Embed the image in the PDF document
      // const image = await this.pdfDoc.embedPng(imageBytes); // or pdfDoc.embedPng(imageBytes) for PNG images

      // // Draw the image on the page
      // page.drawImage(image, {
      //   x: 10,
      //   y: 10,
      //   width: 200,
      //   height: 100,
      // });
      
      // Create a link element to download the image
      // const link = document.createElement('a');
      // link.href = data;
      // link.download = 'drawing_without_background.png'; // Change the filename as desired
      // link.click();

      this.closeSignaturModal();
    },

    clearViewer() {
      const viewer = document.getElementById("viewer-" + this.docId)
      if (viewer != null && viewer != undefined) {
        viewer.innerHTML = ""
      }
    },
  },

  mounted() {
    const wrapper = document.getElementById('pdfViewerWrapper-' + this.docId);
    const wrapperParent = wrapper?.parentElement as HTMLDivElement
    wrapperParent.style.width = "100%"
    wrapperParent.style.height = "100%"
    this.wrapperWidth = wrapperParent?.clientWidth || window.innerWidth;
    // this.wrapperHeight = wrapperParent?.clientHeight || window.innerHeight;
    this.wrapperHeight = window.innerHeight * 0.9;

    const viewer = document.getElementById("viewer-" + this.docId)
    if (viewer?.innerHTML == "") {
      this.loadPDF();
    }
  },
  
  updated () {
    const viewer = document.getElementById("viewer-" + this.docId)
    if (viewer?.innerHTML == "" && !this.pdfloading) {
      this.loadPDF();
    }
  }
})
