import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/profilbild.png'


const _withScopeId = n => (_pushScopeId("data-v-6cab9f0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar navbar-expand-md navbar-light pt-3 px-3" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "navbar-nav me-auto mb-2 mb-md-0" }
const _hoisted_4 = {
  key: 0,
  class: "nav-item my-auto"
}
const _hoisted_5 = {
  key: 1,
  class: "nav-item my-auto"
}
const _hoisted_6 = { class: "navbar-nav ms-auto mb-2 mb-md-0" }
const _hoisted_7 = {
  key: 0,
  class: "nav-item dropdown my-auto ms-5"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  alt: "Profil Bild Rainer Zufall",
  class: "avatar me-2"
}
const _hoisted_10 = {
  class: "nav-link dropdown-toggle d-inline pe-0",
  href: "#",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "dropdown-menu m-0" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 1,
  class: "nav-item dropdown my-auto ms-5"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  src: _imports_0,
  alt: "Profil Bild",
  class: "avatar me-2"
}
const _hoisted_17 = {
  class: "nav-link dropdown-toggle d-inline pe-0",
  href: "#",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "dropdown-menu m-0" }
const _hoisted_20 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_ctx.language == 'en_GB' && _ctx.config != null ? _ctx.config.showZeiterfassungsButtons : false)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              (_ctx.terminalVersion)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-outline-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeApplication()))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "stopwatch",
                      class: "me-2 fa-lg"
                    }),
                    _createTextVNode(" Back to Staff Center ")
                  ]))
                : _createCommentVNode("", true),
              false
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-outline-secondary me-5",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openZeiterfassung()))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "stopwatch",
                      class: "me-2 fa-lg"
                    }),
                    _createTextVNode(" Staff Center ")
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_ctx.config != null ? _ctx.config.showZeiterfassungsButtons : false)
            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                (_ctx.terminalVersion)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-outline-primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeApplication()))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "stopwatch",
                        class: "me-2 fa-lg"
                      }),
                      _createTextVNode(" Zurück zur Zeiterfassung ")
                    ]))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-outline-secondary me-5",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openZeiterfassung()))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "stopwatch",
                        class: "me-2 fa-lg"
                      }),
                      _createTextVNode(" Zeiterfassung ")
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("ul", _hoisted_6, [
        (!_ctx.terminalVersion && _ctx.language == 'en_GB')
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              (_ctx.profilbild != null && _ctx.profilbild.filecontentAsBase64 != '')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: 'data:image/bmp;base64, ' + _ctx.profilbild.filecontentAsBase64,
                    alt: "Profilbild",
                    class: "avatar me-2"
                  }, null, 8, _hoisted_8))
                : (_openBlock(), _createElementBlock("img", _hoisted_9)),
              _createElementVNode("a", _hoisted_10, [
                (_ctx.windowSize)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.user.benutzer), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("ul", _hoisted_12, [
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.logout()))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "arrow-right-from-bracket",
                      class: "me-3"
                    }),
                    _createTextVNode(" Logout ")
                  ])
                ]),
                (_ctx.azureLogin)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                      _createElementVNode("button", {
                        class: "dropdown-item",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.logoutMicrosoft()))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fab', 'windows'],
                          class: "me-3"
                        }),
                        _createTextVNode(" Microsoft Logout ")
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (!_ctx.terminalVersion)
            ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                (_ctx.profilbild != null && _ctx.profilbild.filecontentAsBase64 != '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: 'data:image/bmp;base64, ' + _ctx.profilbild.filecontentAsBase64,
                      alt: "Profilbild",
                      class: "avatar me-2"
                    }, null, 8, _hoisted_15))
                  : (_openBlock(), _createElementBlock("img", _hoisted_16)),
                _createElementVNode("a", _hoisted_17, [
                  (_ctx.windowSize)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.user.benutzer), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("ul", _hoisted_19, [
                  _createElementVNode("li", null, [
                    _createElementVNode("button", {
                      class: "dropdown-item",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "arrow-right-from-bracket",
                        class: "me-3"
                      }),
                      _createTextVNode(" Abmelden ")
                    ])
                  ]),
                  (_ctx.azureLogin)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                        _createElementVNode("button", {
                          class: "dropdown-item",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.logoutMicrosoft()))
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fab', 'windows'],
                            class: "me-3"
                          }),
                          _createTextVNode(" Von Microsoft Abmelden ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}