
import { defineComponent, ref } from "vue";
import KrankenkasseEditModal from "../Modals/KrankenkasseEditModal.vue";


export default defineComponent ({
  name: "KrankenkasseEditvorgang",
  components: {
    KrankenkasseEditModal
  },

  setup () {
    const krankenkasseModalENRef = ref();

    function showModal() {
      krankenkasseModalENRef.value?.showModal()
    }

    return {
      krankenkasseModalENRef,
      showModal
    }
  },

})
