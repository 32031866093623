import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_NameUndAdresseEditModal = _resolveComponent("NameUndAdresseEditModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_font_awesome_icon, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal())),
      icon: "pen-to-square",
      class: "fa-lg pointer"
    }),
    _createVNode(_component_NameUndAdresseEditModal, {
      ref: "nameUndAdresseModalRef",
      index: _ctx.index
    }, null, 8, ["index"])
  ], 64))
}