import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "px-2 d-flex bg-secondary border-bottom border-primary" }
const _hoisted_3 = { class: "text-white" }
const _hoisted_4 = { class: "btn-group" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "text-white ms-2" }
const _hoisted_8 = {
  id: "outerContainer",
  class: "pb-2 bg-secondary h-100"
}
const _hoisted_9 = { id: "mainContainer" }
const _hoisted_10 = { class: "viewerContainer" }
const _hoisted_11 = ["id"]
const _hoisted_12 = ["id", "aria-labelledby"]
const _hoisted_13 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_14 = { class: "modal-content" }
const _hoisted_15 = { class: "modal-header bg-primary p-2" }
const _hoisted_16 = { class: "w-100 d-flex justify-content-between me-3" }
const _hoisted_17 = ["id"]
const _hoisted_18 = ["id"]
const _hoisted_19 = { class: "mx-auto my-auto" }
const _hoisted_20 = { class: "modal-footer p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SignaturCanvas = _resolveComponent("SignaturCanvas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: 'pdfViewerWrapper-' + _ctx.docId,
      class: "bg-secondary d-flex flex-column flex-grow h-100"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["d-flex flex-row align-items-center", !_ctx.options.showPagesOption ? 'd-none' : ''])
        }, [
          _createElementVNode("span", _hoisted_3, "Seiten: " + _toDisplayString(_ctx.numPages), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["d-flex flex-row align-items-center mx-auto", !_ctx.options.showScalingOption ? 'd-none' : ''])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-secondary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoomOut())),
              disabled: _ctx.scale <= 0.1
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "minus" })
            ], 8, _hoisted_5),
            _createElementVNode("button", {
              class: "btn btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoomIn())),
              disabled: _ctx.scale >= 2
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "plus" })
            ], 8, _hoisted_6)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(Math.floor(_ctx.scale * 100)) + "%", 1)
        ], 2),
        _createElementVNode("div", null, [
          false
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-secondary",
                title: "Unterschreiben",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSignaturModal()))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "pen",
                  class: "p-1 fa-lg"
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-secondary", !_ctx.options.showPrintOption ? 'd-none' : '']),
            title: "Drucken",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.printPDF()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "print",
              class: "p-1 fa-lg"
            })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-secondary", !_ctx.options.showDownloadOption ? 'd-none' : '']),
            title: "Herunterladen",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveInFileSystem()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "file-download",
              class: "p-1 fa-lg"
            })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-secondary", !_ctx.options.showEmailSendenOption ? 'd-none' : '']),
            title: "An E-Mail senden",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sendAsEmail()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "envelope",
              class: "p-1 fa-lg"
            })
          ], 2)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              id: 'viewer-' + _ctx.docId,
              class: "pdfViewer"
            }, null, 8, _hoisted_11)
          ])
        ])
      ])
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      class: "modal fade p-0",
      id: 'signaturModalRef' + _ctx.docId,
      tabindex: "-1",
      "aria-labelledby": 'signaturModalRef' + _ctx.docId,
      "aria-hidden": "true",
      "data-bs-backdrop": "static",
      "data-bs-keyboard": "false",
      ref: "signaturModalRef"
    }, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("h5", {
                class: "modal-title my-auto",
                id: 'signaturModalRefLabel' + _ctx.docId
              }, " Signatur ", 8, _hoisted_17)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeSignaturModal()))
            })
          ]),
          _createElementVNode("div", {
            class: "modal-body bg-dark",
            id: 'signaturModalRefBody' + _ctx.docId
          }, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_SignaturCanvas, {
                onInsertSignaturImg: _ctx.insertSignaturImg,
                ref: "signaturCanvasRef"
              }, null, 8, ["onInsertSignaturImg"])
            ])
          ], 8, _hoisted_18),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeSignaturModal()))
              }, "Abbrechen"),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary ms-3",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.triggerInsertSignatur()))
              }, "Einfügen")
            ])
          ])
        ])
      ])
    ], 8, _hoisted_12)
  ], 64))
}