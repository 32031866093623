
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { KontaktinformationenEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    let cryptuserpassword_2 = ref("")

    const state = reactive({
      email: personaldaten.value.email,
      cryptuserpassword: "",
      notfallkontakt: personaldaten.value.notfallname,
      notfallkontaktDatenschutz: false,
      tel: personaldaten.value.tel,
      mobil: personaldaten.value.mobil,
      speakapemail: personaldaten.value.chatadresse,
      responseEmailAdresse: personaldaten.value.email,
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.email = personaldaten.value.email;
      state.cryptuserpassword = "";
      state.notfallkontakt = personaldaten.value.notfallname;
      state.notfallkontaktDatenschutz = false;
      state.tel = personaldaten.value.tel;
      state.mobil = personaldaten.value.mobil;
      state.speakapemail = personaldaten.value.chatadresse;
      cryptuserpassword_2.value = "";
      errorMessage.value = "";
      state.responseEmailAdresse = personaldaten.value.email;
    }

    const kontaktdatenEditModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(kontaktdatenEditModalRef.value)?.hide();
    }
    const showModal = () => {
      if (kontaktdatenEditModalRef.value) {
        new Modal(kontaktdatenEditModalRef.value).show();
      }
    }

    return {
      store,
      loading,
      status,
      ...toRefs(state),
      cryptuserpassword_2,
      errorMessage,
      kontaktdatenEditModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      if (this.cryptuserpassword != this.cryptuserpassword_2) {
        this.errorMessage = "Ungültiges Passwort.\r\nBitte geben Sie in beiden Feldern das gleiche Passwort ein!";
      }
      else if (this.notfallkontakt != "" && !this.notfallkontaktDatenschutz) {
        this.errorMessage = "Bitte bestätigen Sie, dass der Notfallkontakt\r\nmit der Speicherung der Daten einverstandnen ist.";
      }

      else {

        const kontaktdatenEditDataRequest: KontaktinformationenEditDataRequest = {
          email: this.email,
          cryptuserpassword: this.cryptuserpassword,
          notfallname: this.notfallkontakt,
          tel: this.tel,
          mobil: this.mobil,
          speakapemail: this.speakapemail,
          responseEmailAdresse: this.responseEmailAdresse
        }

        this.store.dispatch(PersonalActionTypes.UpdatePersonaldatenKontaktinformationen, kontaktdatenEditDataRequest)
          .then(() => {
            
            if (this.status.personalErrorMsg != "") {
              this.errorMessage = this.status.personalErrorMsg;
            }
            else {
              this.closeModal();
              this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
            }
          });
      }
      
    },

    togglePasswordVisibility (id: string) {
      const input = document.getElementById(id) as HTMLInputElement;
      input.type = input.type === "password" ? "text" : "password";
    },

    checkInputFirst(e: KeyboardEvent) {
      let notAllowedChars = [';', ','];
      if (notAllowedChars.includes(e.key)) {
        e.preventDefault();
      }
    },

    setInputEventListener () {
      const notfallkontakt = document.getElementById("notfallname") as HTMLElement | null
      notfallkontakt?.addEventListener("keydown", this.checkInputFirst);
      
      const tel = document.getElementById("tel") as HTMLElement | null
      tel?.addEventListener("keydown", this.checkInputFirst);
      
      const mobil = document.getElementById("mobil") as HTMLElement | null
      mobil?.addEventListener("keydown", this.checkInputFirst);
    }
  },

  mounted () {
    this.setInputEventListener();
  }

})
