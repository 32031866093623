
import { defineComponent, computed, PropType } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels";

export default defineComponent({
  name: "PostfachNachricht",
  
  props: {
    nachricht: {
      type: Object as PropType<PostfachNachricht>,
      required: true
    }
  },

  methods: {
    setGelesenStatus () {
      this.store.dispatch(NachrichtenActionTypes.SetPostfachNachrichtGelesen, this.nachricht.id)
    }
  },

  setup(props) {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const adateConverter = computed(() => {
      const adate = new Date(props.nachricht.adate);

      return ("0" + adate.getDate()).slice(-2) + "." + ("0" + (adate.getMonth() + 1)).slice(-2) + "." + adate.getFullYear() + " " + ("0" + (adate.getHours())).slice(-2) + ":" + ("0" + (adate.getMinutes())).slice(-2);
    })

    return {
      store,
      adateConverter,
      language
    }
  }
})
