
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModal from "../Modals/KontaktinformationenEditModal.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModal
  },

  setup () {
    const kontaktinformationenModalRef = ref();
    function showModal() {
      kontaktinformationenModalRef.value?.showModal()
    }

    return {
      kontaktinformationenModalRef,
      showModal
    }
  },
})
