
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as VorgangActionTypes } from "@/store/modules/Vorgang/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { NachrichtAnHR } from "@/models/Vorgange/VorgangeModels";

export default defineComponent ({
  name: "NachrichtAnHR_EN",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.vorgangLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      betreff: "",
      message: "",
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.betreff = "",
      state.message = "",
      state.responseEmailAdresse = personaldaten.value.email,
      state.postfachartid = 1
      errorMessage.value = "";
    }

    const NachrichtAnHRModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(NachrichtAnHRModalRef.value)?.hide();
    }
    const showModal = () => {
    if (NachrichtAnHRModalRef.value) {
      new Modal(NachrichtAnHRModalRef.value).show();
    }
  }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      NachrichtAnHRModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: NachrichtAnHR = {
        betreff: this.betreff,
        message: this.message,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }


      this.store.dispatch(VorgangActionTypes.SendNachrichtAnHR, data)
        .then(() => {
          
          if (this.status.vorgangErrorMsg != "") {
            this.errorMessage = this.status.vorgangErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    }
  }

})
