
import { computed, defineComponent } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import PostfachNachricht from "@/components/Postfach/PostfachNachricht.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue"


export default defineComponent({
  name: "Postfach",
  components: {
    PostfachNachricht,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const loading = computed(() => store.getters.status.nachrichtenLoading)
    const language = computed(() => store.getters.language)

    const posteingang = computed(() => store.getters.postfachPosteingang)

    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)

    return {
      store,
      loading,
      language,
      posteingang,
      postfachLastUpdate,
      postfachLastUpdateInterval,
    }
  },
  
  mounted () {
    const now = new Date();

    if (now.getTime() - new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
  }
});
